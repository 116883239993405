<template>
    <div>
        <Navbar />
        <!-- <PageTitle pageTitle="Blog Details" /> -->
        <BlogDetails />
        <FooterStyleFour />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import BlogDetails from '../BlogDetails/BlogDetails'
 import FooterStyleFour from '../Layout/FooterStyleFour'

export default {
    name: 'BlogPageOne',
    components: {
        Navbar,
        PageTitle,
        BlogDetails,
        FooterStyleFour,
    }
}
</script>