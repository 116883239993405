<template>
  <div class="app-about-area pb-100 pt-100 mt-5">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="app-about-image">
            <img
              src="../../../assets/images/more-home/about/First-images.png"
              alt="image"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="app-about-content">
            <div class="big-text">About Us</div>
            <span class="sub-title text-uppercase">ABOUT US</span>
            <h2>
              Cultivating Business Success with Meander Software

            </h2>
            <p>
              Since 2014, Meander Software has been empowering companies and
              established brands to reimagine their business through
              digitalization. We specialize in expert app and website
              development, app clone, and digital marketing solutions tailored
              to diverse business needs. Collaborate with us to elevate your
              business with cutting-edge technology
            </p>
            <ul class="list">
              <li>
                <div class="icon">
                  <i class="ri-award-line"></i>
                </div>
                <h3>Core Values</h3>
                <p>
                  At Meander Software, integrity, innovation, collaboration, and
                  client satisfaction are our guiding principles. We prioritize
                  ethical conduct, continuously seek new solutions, foster
                  teamwork, and aim to exceed client expectations. These values
                  shape our culture and drive our commitment to excellence in
                  every project.
                </p>
              </li>
              <li>
                <div class="icon bg2">
                  <i class="ri-download-cloud-2-line"></i>
                </div>
                <h3>Our Vision</h3>
                <p>
                  Meander Software imagines a future where technology enhances
                  life for all. Our aim is to craft inventive solutions,
                  breaking barriers for a more accessible digital world. Through
                  creativity and determination, we empower individuals and
                  businesses to flourish in a more connected environment
                </p>
              </li>
              <li>
                <!-- <div class="icon bg2">
                  <i class="ri-download-cloud-2-line"></i>
                </div>
                <h3>our vision</h3> -->
                <button @click="openCalendly" class="default-btn">
                Schedule Demo
              </button>
              </li>
            </ul>
            <!-- <div class="btn-box">
              <button @click="openCalendly" class="default-btn">
                Schedule Demo
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="app-about-shape-1">
      <img
        src="../../../assets/images/more-home/about/shape-1.png"
        alt="image"
      />
    </div>
    <div class="app-about-shape-2">
      <img
        src="../../../assets/images/more-home/about/shape-2.png"
        alt="image"
      />
    </div>
    <div class="app-about-shape-3">
      <img
        src="../../../assets/images/more-home/about/shape-3.png"
        alt="image"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  methods: {
    openCalendly() {
      // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({
        url: "https://calendly.com/connect-to-meander/30min",
      });
      return false;
    },
  },
};
</script>
