<template>
    <div class="feedback-area bg-F4F8FC ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>Industries We Serve</h2>
            </div>
            <div class="feedback-tabs">
                <div class="tab-content">
                    <div class="tabs__container">
                        <div 
                            class="tabs__list"
                            ref='tabsList'
                        >
                            <div 
                                class="tabs__list-tab"
                                v-for="tab in tabs"
                                v-bind:class="{ 'active': activeTab == tab.id }"
                                :key="tab.id"
                            >
                                <p>{{tab.description}}</p>
                                <h6 class="fx-bold">
                                    <span>{{tab.name}}</span> 
                                    <!-- - Web Developer -->
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs">
                    <ul class="tabs__header">
                        <li 
                            class="tabs__header-item" 
                            v-for="tab in tabs" 
                            v-on:click="selectTab (tab.id)" 
                            v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id"
                        >
                            <img :src="tab.image" alt="user">
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feedback',
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            tabs: [
                {
                    id: 1,
                    description: 'Meander Software provides tailored software solutions for education and training providers. Our solutions include learning management systems, virtual classrooms, and online assessment tools, enabling institutions to deliver high-quality content and training to their students seamlessly.',
                    name: 'Education & Training',
                    position: '',
                    image: require('../../assets/images/user/4.png')
                },
                {
                    id: 2,
                    description: ' Meander Software offers personalized ecommerce solutions that help businesses enhance their online presence and expand their audience reach. Our solutions include online marketplaces, mobile apps, and payment gateway integrations, allowing businesses to sell their products and services online seamlessly.',
                    name: 'Retail & Ecommerce',
                    position: '',
                    image: require('../../assets/images/user/5.png')
                },
                {
                    id: 3,
                    description: 'Meander Software provides innovative healthcare solutions to help providers deliver better patient care and streamline operations. Our solutions include electronic health records, telemedicine platforms, and patient engagement tools, empowering providers to deliver effective and efficient care to their patients.',
                    name: 'Health & wellness',
                    position: '',
                    image: require('../../assets/images/user/6.png')
                },
                {
                    id: 4,
                    description: 'Meander Software offers tailored CRM solutions to help businesses manage customer relationships more effectively. Our solutions include customer database management, sales automation tools, and customer analytics, enabling businesses to better understand their customers needs and preferences.',
                    name: 'CRM',
                    position: '',
                    image: require('../../assets/images/user/7.png')
                },
                {
                    id: 5,
                    description: ' Meander Software offers logistics solutions that help businesses to streamline their operations and improve their supply chain management. Our solutions include transportation management systems, warehouse management systems, and inventory management tools that enable businesses to reduce costs and improve efficiency.',
                    name: 'Logistics',
                    position: '',
                    image: require('../../assets/images/user/8.png')
                },
                {
                    id: 6,
                    description: 'Meander Software provides fintech solutions that help financial institutions to improve their operations and enhance their customer experience. Our solutions include mobile banking apps, payment processing systems, and financial analytics tools that enable institutions to provide better services to their customers.',
                    name: 'Fintech',
                    position: '',
                    image: require('../../assets/images/user/9.png')
                },
                {
                    id: 7,
                    description: 'Meander Software offers social networking solutions that help businesses to engage with their customers and build communities around their brands. Our solutions include social media management tools, community forums, and user-generated content platforms that enable businesses to foster greater customer engagement and loyalty.',
                    name: 'Social Networking',
                    position: '',
                    image: require('../../assets/images/user/10.png')
                },
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    }
}
</script>