<template>
    <div class="features-area pt-100 pb-75" >
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-content">
                        <span class="sub-title text-uppercase  ">#Why Choose Drawtopic Digital</span>
                        <h2>Your Partner for Digital Marketing Success</h2>
                        <p>Choose Drawtopic Digital as your digital marketing partner and unlock the potential of your online presence. Our dedication, expertise, and personalized strategies will empower your business to thrive in the digital landscape, attract your target audience, and drive sustainable growth.
                              </p>
                              <p>At Drawtopic Digital, we pride ourselves on being a trusted partner in your digital marketing journey.</p>
                        <div class="btn-box">
                            <a @click="openCalendly" class="default-btn">Schedule Demo</a>
                            <!-- <a href="#" class="link-btn">See All Features</a> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12" >
                    <div class="list-of-features">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Proven Expertise</h3>
                                    <p> Years of industry experience ensure exceptional results, staying updated with the latest trends.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Tailored Solutions</h3>
                                    <p> Customized plans for unique businesses, maximizing effectiveness and ROI.
</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Results-Driven</h3>
                                    <p>Data-backed strategies, continuous optimization, and detailed reporting for measurable growth and success.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>Transparent Communication</h3>
                                    <p> Open, prompt, and regular updates on campaign progress, fostering strong client relationships.</p>
                                </div>
                            </div>
                          
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhatDoWeOffer',
    props: {
        data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
},
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>