<!-- <template>
  <Preloader v-if="isLoading" />
  <div class="about-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="about-content">
            <span class="sub-title text-uppercase  ">{{ this.data[0]?.attributes?.ms_apps_about_uses?.data[0]?.attributes?.title || "ABOUT MEANDER SOFTWARE" }}</span>
            <h2>Most Probably You Are Getting Best App Ever</h2>
            <p>{{ this.data[0]?.attributes?.ms_apps_about_uses?.data[0]?.attributes?.description || 
            "Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began."
            }}</p>
            <div class="features-text">
              <h6>Our App</h6>
              <p>{{ this.data[0]?.attributes?.ms_apps_about_uses?.data[0]?.attributes?.our_app || "Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business" }}</p>
            </div>
            <div class="features-text">
              <h6>Our Mission</h6>
              <p>{{ this.data[0]?.attributes?.ms_apps_about_uses?.data[0]?.attributes?.our_mission || "Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy of your business purpose text." }}</p>
            </div>
            <div class="btn-box">
              <button @click="openCalendly" class="default-btn">
                Schedule Demo
              </button>
              <router-link to="/features-one" class="link-btn">See All Features</router-link>
            </div>

          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="about-img">
            <img
              src="../../assets/images/app/app-img8.png"
              data-aos="fade-up"
              alt="about"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Preloader from "../Layout/Preloader.vue";
export default {
  name: "About",
  props: {
    data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
  },
  components: {
    Preloader,
  },
  data() {
    return {
      about: this.data[0]?.attributes?.ms_apps_about_uses?.data[0]?.attributes,
      isLoading: true,
      baseUrl: "https://content.meander.software/",
      aboutUs: Object,
    };
  },
  methods: {
    openCalendly() {
      Calendly.initPopupWidget({
        url: "https://calendly.com/connect-to-meander/30min",
      });
      return false;
    },

  },
  mounted: async function () {
    this.isLoading = false;
  },
};
</script> -->
<template>
  <div class="app-about-area pb-100 pt-100 ">
      <div class="container-fluid">
          <div class="row align-items-center">
              <div class="col-lg-6 col-md-12">
                  <div class="app-about-image">
                    <img v-if="this.imageData && this.imageData[0]?.attributes?.ms_apps_about_uses?.data[0]?.attributes?.image?.data?.attributes?.url" 
     :src="'https://content.meander.software/' + this.imageData[0]?.attributes?.ms_apps_about_uses?.data[0]?.attributes?.image?.data?.attributes?.url" 
     alt="image">

    <img v-else 
         src="../../assets/images/more-home/about/about.png" 
         alt="fallback image">

                  </div>
              </div>

              <div class="col-lg-6 col-md-12">
                  <div class="app-about-content">
                      <div class="big-text">About App</div>
                      <span class="sub-title text-uppercase  ">About App</span>
                      <h2>Most Probably You Are Getting Best App Ever</h2>
                      <p>{{ this.data[0]?.attributes?.ms_apps_about_uses?.data[0]?.attributes?.description || 
            "Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began."
            }}</p>
                      <ul class="list">
                          <li>
                              <div class="icon">
                                  <i class="ri-award-line"></i>
                              </div>
                              <h3>Our App</h3>
                              <p>{{ this.data[0]?.attributes?.ms_apps_about_uses?.data[0]?.attributes?.our_app || "Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business" }}</p>

                          </li>
                          <li>
                              <div class="icon bg2">
                                  <i class="ri-download-cloud-2-line"></i>
                              </div>
                              <h3>Our Mission</h3>
                              <p>{{ this.data[0]?.attributes?.ms_apps_about_uses?.data[0]?.attributes?.our_mission || "Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy of your business purpose text." }}</p>

                          </li>
                      </ul>
                      <div class="btn-box">
                           <button @click="openCalendly" class="default-btn">Schedule Demo</button>
                          <!-- <router-link to="/features-one" class="link-btn">See All Features</router-link> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="app-about-shape-1">
          <img src="../../assets/images/more-home/about/shape-1.png" alt="image">
      </div>
      <div class="app-about-shape-2">
          <img src="../../assets/images/more-home/about/shape-2.png" alt="image">
      </div>
      <div class="app-about-shape-3">
          <img src="../../assets/images/more-home/about/shape-3.png" alt="image">
      </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  props: {
    imageData: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      about: this.data[0]?.attributes?.ms_apps_about_uses?.data[0]?.attributes,
      isLoading: true,
      baseUrl: "https://content.meander.software/",
      aboutUs: Object
    };
  },
  methods: {
    openCalendly() {
      // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
      return false;
    },
  },
  mounted() {
    this.isLoading = false;
    // Console log the image URL
    console.log("Image URL:", this.imageData[0]?.attributes?.ms_apps_about_uses.data[0]?.attributes?.image?.data?.attributes.url,this.data);
    
  }
}
</script>

