<template>
    <div class="features-area pt-50 pb-50" >
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-content">
                        <span class="sub-title text-uppercase  ">#Key Features
</span>
                        <h2>Core Principles for Successful Business Operations
</h2>
                        <p>At Meander Software, we understand that every business has unique needs, and that's why we offer customized software solutions that are tailored to meet the specific needs of each client. We take the time to understand our clients' requirements and design solutions that are scalable, secure, and aligned with their business objectives. Our transparent process ensures that our clients are involved in every stage of the development process, from planning and design to development and testing.
                              </p>
                        <div class="btn-box">
                            <a @click="openCalendly" class="default-btn">Schedule Demo</a>
                            <!-- <a href="#" class="link-btn">See All Features</a> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12" >
                    <div class="list-of-features">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-star-smile-line"></i>
                                    </div>
                                    <h3>Stellar Communication:</h3>
                                    <p> Regular meetings, open-door policy, and training ensure clarity and teamwork.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-file-list-3-line"></i>
                                    </div>
                                    <h3>Agile Adaptation</h3>
                                    <p>Embrace agile methods, empower teams, and prioritize customer feedback for swift adaptation.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-medal-2-line"></i>
                                    </div>
                                    <h3>Innovation Cultivation</h3>
                                    <p>Foster experimentation, feedback loops, and continuous improvement for growth.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6" >
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-money-pound-circle-line"></i>
                                    </div>
                                    <h3> Client-Centric Focus</h3>
                                    <p>We offer competitive pricing that is tailored to each project's unique requirements, ensuring that our clients receive the best value for their investment.</p>
                                </div>
                            </div>
                          
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features',
    props: {
        data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
},
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>