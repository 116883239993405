<template>
  <div>
    <Preloader v-if="isLoading" />
    <router-view />
    <GoTop />
    <WhatsApp/>
  </div>
</template>

<script>
import Preloader from './components/Layout/Preloader'
import GoTop from './components/Layout/GoTop'
import WhatsApp from './components/Layout/WhatsApp.vue'
export default {
  name: 'App',
  components: {
    Preloader,
    GoTop,
    WhatsApp
  },
  data() {
    return {
      isLoading: true,
      apiCalled: false // Add a flag to track whether API is called or not
    }
  },
  // watch: {
   
  //       if (newSlug) {
  //         this.getData2(newSlug);
  //       }
   
  // },

 async mounted () {
  
  // await this.getData()
    const s1 = document.createElement("script");
    const s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/620f8c201ffac05b1d7a894f/1fs6bmodt";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
    
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
  methods:{
  
  async getData() {
  
  await fetch('http://localhost:8979/api/sitemap')
     .then(response => response.text())
     .then(xml => {
       // Handle the response as needed
       this.apiCalled = true; // Set the flag to true after API call
     })
     .catch(error => {
       console.error('Error fetching sitemap:', error);
     });
   }
},
}
</script>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { useRoute } from 'vue-router'

const canonicalUrl = ref('')

onMounted(() => {
  const route = useRoute()

  const updateCanonicalUrl = () => {
    canonicalUrl.value = 'https://www.meander.software' + route.path
  }

  const routeWatcher = watch(() => route.path, updateCanonicalUrl)

  updateCanonicalUrl()

  const canonicalLink = document.head.querySelector('link[rel="canonical"]')
  if (canonicalLink) {
    canonicalLink.setAttribute('href', canonicalUrl.value)
  } else {
    const newCanonicalLink = document.createElement('link')
    newCanonicalLink.rel = 'canonical'
    newCanonicalLink.href = canonicalUrl.value
    document.head.appendChild(newCanonicalLink)
  }

  onBeforeUnmount(() => {
    routeWatcher()
    updateCanonicalUrl()
  })
})
</script>
