<template>
    <div class="loader">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="spinner"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Preloader'
}
</script>