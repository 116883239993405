<template>
    <div>
        <Navbar />
        <!-- <PageTitle pageTitle="Meander LIVE" /> -->
        <MainBanner />
        <About />
        <Benefits/>
        <Features/>
        <HowItWorks/>
        <WhatDoWeOffer/>
        <Youtube/>
        <!-- <CustomFeatures /> -->
        <!-- <Features /> -->
        <!-- <MeanderFeatures /> -->
        <!-- <HowItWorks /> -->
        <!-- <BestFeaturesEver /> -->
        <!-- <Video /> -->
        <Faq />
        <CustomerReviews />
        <CustomTeam />
        <Partner />
        <FreeTrial />
        
        <FooterStyleFour />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import MainBanner from "./MainBanner"
import About from "./About"
import CustomFeatures from "../FeaturesTwo/CustomFeatures"
import MeanderFeatures from "../FeaturesTwo/MeanderFeatures"
import HowItWorks from "./HowItWorks.vue"
import BestFeaturesEver from "../FeaturesOne/BestFeaturesEver"
import Video from "../HomeOne/Video"
import Faq from "../Faq/Faq"
import  CustomerReviews from "../Common/CustomerReviews"
import CustomTeam from '../TeamTwo/CustomTeam.vue'
import Partner from "../Common/Partner"
  import FreeTrial from "../HomeOne/FreeTrial"



 import FooterStyleFour from '../Layout/FooterStyleFour'
import Benefits from './Benefits.vue'
import Features from './Features.vue'
import WhatDoWeOffer from './WhatDoWeOffer'
import Youtube from './Youtube.vue'
export default {
    name: 'MeanderLive',
    components: {
    Navbar,
    PageTitle,
    MainBanner,
    About,
    CustomFeatures,
    MeanderFeatures,
    HowItWorks,
    BestFeaturesEver,
    Video,
    Faq,
    CustomerReviews,
    CustomTeam,
    Partner,
    FreeTrial,
    FooterStyleFour,
    Benefits,
    Features,
    WhatDoWeOffer,
    Youtube
},
methods:{
    gettilte(){
        document.title = "product"
    }
},
mounted(){
    this.gettilte()
}

}
</script>