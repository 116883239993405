<template>
    <div class="app-download-area">
        <div class="container">
            <div class="app-download-inner">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="app-download-content">
                            <span class="sub-title text-uppercase  ">DOWNLOAD APP</span>
                            <h2>Let's Get Your Free Copy From Apple and Play Store</h2>
                            <p>Instant free download from store Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text.</p>
                            <div class="btn-box">
                                <a href="#" class="playstore-btn" target="_blank">
                                    <img src="../../assets/images/play-store.png" alt="image">
                                    Get In Touch
                                    <!-- <span>Google Play</span> -->
                                </a>
                                <a href="#" class="applestore-btn" target="_blank">
                                    <img src="../../assets/images/apple-store.png" alt="image">
                                    DownAll Rights Reserved byad on the
                                    <span>Apple Store</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="app-download-image" data-aos="fade-up">
                            <img src="../../assets/images/app/app-img3.png" alt="app-img">
                        </div>
                    </div>
                </div>
                <div class="shape5"><img src="../../assets/images/shape/shape4.png" alt="shape4"></div>
                <div class="lines">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppDownload'
}
</script>