<template>
    <div>
       
       
        <!-- Start Features Area -->
        <div class="features-area ptb-100 bg-F7F7FF">
            <div class="container">
                <div class="section-title">
                    <!-- <span class="sub-title text-uppercase  ">KEY FEATURES</span> -->
                    <h2>    A Step-by-Step Guide to Creating and Marketing Your Business.</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon">
                                <i class="ri-eye-line"></i>
                            </div>
                            <h3>Client Consultation</h3>
                            <p>Understand the client's requirements, objectives, and budget. Determine the specific app they want to clone and any customizations needed.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg2">
                                <i class="ri-stack-line"></i>
                            </div>
                            <h3>Market Research</h3>
                            <p> Analyze the target market, competition, and user preferences related to the chosen app. Identify unique selling points.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg3">
                                <i class="ri-leaf-line"></i>
                            </div>
                            <h3>Design and Customization</h3>
                            <p>Develop the app's user interface, considering branding and design elements. Customize the clone app to meet the client's specific needs.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg4">
                                <i class="ri-secure-payment-line"></i>
                            </div>
                            <h3>Quality Assurance</h3>
                            <p>Conduct rigorous testing to identify and rectify any bugs or issues. Ensure the app performs seamlessly and meets quality standards.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg5">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>
Client Testing</h3>
                            <p>Provide the client with a demo version of the app for testing and feedback. Make necessary adjustments based on their input.
</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg6">
                                <i class="ri-pie-chart-2-line"></i>
                            </div>
                            <h3>
Launch and Marketing</h3>
                            <p>Assist the client in launching the app, including app store optimization (ASO) and marketing strategies to attract users</p>
                        </div>
                    </div>
                    <!-- <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                        <div class="view-more-box">
                            <a href="#" class="default-btn">View More</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- End Features Area -->

    </div>
</template>

<script>
export default {
    name: 'MeanderFeatures',
}
</script>