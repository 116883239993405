<template>
  <FormModal v-if="showPopup" :closePopup="closePopup" />

    <div>
        <Navbar class="navbar-style-three" :openPopup="openPopup"/>
        <!-- <PageTitle class="bg-color" pageTitle="Blog Left Sidebar" /> -->
        <Blog />
        <FooterStyleFour />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
 
import Blog from '../BlogThree/Blog.vue'
 import FooterStyleFour from '../Layout/FooterStyleFour'
import FormModal from '../HomeThree/FormModal.vue'

export default {
    name: 'Resources',
    components: {
    Navbar,
  
    Blog,
    FooterStyleFour,
    FormModal,
    
},
data(){
 return{
    showPopup :false
 }
},
    methods:{
        openPopup(){
      this.showPopup= true
    },
    closePopup(){
      this.showPopup= false
    },
     addtitle(){
       document.title= "resources"
     }
    },
    mounted() {
        this.addtitle()
    },
}
</script>