<template>
    <div class="key-features-area bg-transparent-with-color pt-100 pb-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title text-uppercase  ">KEY FEATURES</span>
                <h2>Most Probably Included Best Features Ever</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                    <div class="key-features-card style-two">
                        <div class="icon">
                            <i class="ri-eye-line"></i>
                        </div>
                        <h3>High Resolution</h3>
                        <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                    <div class="key-features-card bg-color-two style-two">
                        <div class="icon bg2">
                            <i class="ri-stack-line"></i>
                        </div>
                        <h3>Retina Ready Screen</h3>
                        <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                    <div class="key-features-card style-two">
                        <div class="icon">
                            <i class="ri-leaf-line"></i>
                        </div>
                        <h3>Easy Editable Data</h3>
                        <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                    <div class="key-features-card bg-color-two style-two">
                        <div class="icon bg2">
                            <i class="ri-secure-payment-line"></i>
                        </div>
                        <h3>Fully Secured</h3>
                        <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                    <div class="key-features-card style-two">
                        <div class="icon">
                            <i class="ri-cloud-line"></i>
                        </div>
                        <h3>Cloud Storage</h3>
                        <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                    <div class="key-features-card bg-color-two style-two">
                        <div class="icon bg2">
                            <i class="ri-pie-chart-2-line"></i>
                        </div>
                        <h3>Responsive Ready</h3>
                        <p>Just log in with your mail account from play store and using whatever you want for your able business purpose.</p>
                    </div>
                </div>
            </div>

            <div class="key-features-btn">                          
                 

                 <button @click="openCalendly" class="default-btn">Schedule Demo</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BestFeaturesEver',
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>