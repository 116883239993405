<template>
    <div class="stats-area ptb-50">
        <div class="container">
            <div class="section-title">
                <span class="sub-title text-uppercase  ">OUR STATS</span>
                <h2>Total User In This World</h2>
            </div>
            <div class="stats-map text-center">
                <!-- <img src="../../assets/images/mapnew.png" alt="map"> -->
                <img src="../../assets/images/map1.png" alt="map">

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Stats'
}
</script>