<template>
  <div @click="closePopup" class="popup-overlay">

    <div class="popup" style="border-radius: 10px ;">

      <button type="button" class="btn-close" aria-label="Close"></button>
      <iframe :src="formUrl" width="700" height="648" style="border-radius: 10px ;"></iframe>
      <!-- <button class="cancel">Close</button> -->
    </div>
  </div>
</template>
<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;

  button {
    margin-top: 10px;
    position: absolute;
    top: 0 px;
    border-radius: 10px;
    overflow: hidden !important;
    right: 450px;
  }

  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999999 !important;
}

.popup {
  position: relative;
  background-color: white;
  /* padding: 20px; */
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 80%;
  height: 80vh;
  border-radius: 10px;
}

.gradientPageBg .backgroundBg,
.gradientPageBg .tyBackgroundBg,
.gradientPageBg .saveandReswrap,
.gradientPageBg .splashMsgWrapper .splashMsgCont,
.gradientPageBg .ofSubformWrapper,
.gradientPageBg .pageReviewPopUp,
.gradientPageBg .ofOuterPopupBg {
  border-radius: 10px !important;
  overflow: hidden !important;
}

button {
  margin-top: 10px;
  position: absolute;
  top: 0 px;
  right: 10px;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>


<script>
export default {
  name: 'MainBanner',
  props: {
    closePopup: Function,

    data: {
      type: [], // Set the prop type to be Any
      required: true,

    },

  },

  data() {
    return {
      baseUrl: 'https://content.meander.software/',
      isLoading: true,
      //   showPopup: false,
      formUrl: "https://forms.zohopublic.in/meandersoftwarecontact/form/ClientNeedsAssessmentForm/formperma/4fjH7ZBQUI7rdg6ZqA85n4ldQD4ajNkC_-RXMdrXXPs"
    }
  },
  methods: {
    //     openPopup() {
    //   this.showPopup = true;
    // },
    // closePopup() {
    //   this.showPopup = false;
    // },
    openFormPopup() {
      // eslint-disable-next-line no-undef
      zforms_open_popup(
        'https://forms.zohopublic.in/meandersoftwarecontact/form/ClientNeedsAssessmentForm/formperma/4fjH7ZBQUI7rdg6ZqA85n4ldQD4ajNkC_-RXMdrXXPs',
        648,
        700
      );
    },


  }
}
</script>