<template>
    <div >
       
        <!-- Start Features Area -->
        <div class="features-area pt-100 bg-f9f9f9 pb-75">
            <div class="container">
                <div class="section-title">



                    <span class="sub-title text-uppercase "> {{ data[0]?.attributes?.ms_services_offers.data[0]?.attributes?.name ||"Our Offering" }}</span>
                    <h2>{{ data[0]?.attributes?.ms_services_offers.data[0]?.attributes?.description || "Empower your business with our mobile development services"   }}</h2>
                    <!-- <p>Our application development services include consultation, implementation, user interface/user experience, and quality and security assurance across iOS, Android and Cross-platform.</p> -->
                </div>
                <div class="row justify-content-center">

                   

                    <div class="col-lg-4 col-sm-6 col-md-6"  v-for="(items,index) in data[0]?.attributes?.ms_services_key_offers?.data"  :key="items.id">
                        <div class="features-item with-border">
                            <div class="icon">
                                <i :class="getIcon(index)"></i>
                            </div>
                            <h3>{{items?.attributes?.name}}</h3>
                            <p>{{items?.attributes?.description}}</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <!-- End Features Area -->

    </div>
</template>

<script>
export default {
    name: 'ServiceOffering',
    props: {
        data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
    },
     
    methods:{
        getIcon(index) {
        const icons = [
            'ri-eye-line',
            'ri-stack-line',
            'ri-leaf-line',
            'ri-secure-payment-line',
            'ri-cloud-line',
            'ri-pie-chart-2-line',
            // Add more icons as needed
        ];

        // Return the icon based on the index or a default icon if the index is out of bounds
        return icons[index] || 'ri-eye-line';
    },
    }
}
</script>