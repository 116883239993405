<template>
    <div>
       
        <!-- Start Features Area -->
        <div class="features-area pt-100 bg-f9f9f9 pb-75">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title text-uppercase  "> {{ data[0]?.attributes?.ms_technologies_features.data[0]?.attributes?.name  }}</span>
                    <h2>{{ data[0]?.attributes?.ms_technologies_features.data[0]?.attributes?.descripition }}</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-sm-6 col-md-6"  v-for="items in data[0]?.attributes?.ms_technology_key_features?.data"  :key="items.id">
                        <div class="features-item with-border">
                            <div class="icon">
                                <i class='ri-eye-line'></i>
                            </div>
                            <h3>{{items?.attributes?.title}}</h3>
                            <p>{{items?.attributes?.description}}</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <!-- End Features Area -->

    </div>
</template>

<script>
export default {
    name: 'TechnologiesFeatures',
    props: {
         data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
    },
    data(){
        return{
            
        icons:[
            {
                id:1,
                icon1:'ri-eye-line',

            },
            {
                id:2,

            icon1:'ri-stack-line',

            },
            {
                id:3,
                icon1:'ri-leaf-line',
            },
            {
                id:4,
                icon1:'ri-secure-payment-line',

            },
            {
                id:5,
                icon1:'ri-cloud-line',
            },
            {
                id:6,
                icon1:'ri-pie-chart-2-line',
            }
        ]
            
           
            
        }
    }
}
</script>