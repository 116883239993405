<template>
    <div class="banner-area">
        <div class="container-fluid">
            <div class="row align-items-center m-0">
                <div class="col-lg-6 col-md-12 p-0">
                    <div class="banner-content">
                        <span class="sub-title text-uppercase  ">Get your 14 days free trail</span>
                        <h1>The Revolutionary App That Makes Your Life Easier Than Others</h1>
                        <p>Discover the revolutionary app that simplifies your life like never before. Experience unparalleled convenience and efficiency with this innovative solution.</p>
                        <router-link to="/pricing" class="default-btn">Schedule Demo</router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 p-0">
                    <div class="banner-image" data-aos="fade-down" data-aos-duration="2000">
                        <img src="../../assets/images/banner/banner-app2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <div class="banner-shape3">
            <img src="../../assets/images/shape/shape2.png" alt="image">
        </div>
        <div class="shape13">
            <img src="../../assets/images/shape/shape15.png" alt="shape">
        </div>
        <div class="banner-shape6">
            <img src="../../assets/images/shape/shape16.png" alt="image">
        </div>
        <div class="banner-shape7">
            <img src="../../assets/images/shape/shape11.png" alt="image">
        </div>
        <div class="banner-shape8">
            <img src="../../assets/images/shape/shape11.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>