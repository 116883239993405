<template>
    <div class="features-area pt-100 pb-75">
        <div class="container">
            <div class="row align-items-center" >
                <div class="col-lg-6 col-md-12">
                    <div class="features-content">
                        <span class="sub-title text-uppercase  ">what do we offer</span>
                        <h2>{{ data[0]?.attributes?.ms_services_what_do_we_offers?.data[0]?.attributes?.name ||"what do we offer" }}</h2>
                        <p>{{ data[0]?.attributes?.ms_services_what_do_we_offers?.data[0]?.attributes?.description ||"Elevate Your App's Potential with Our Services" }}.</p>
                        <!-- <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want chance its just began.</p> -->
                        <div class="btn-box">
                            <a @click="openCalendly" class="default-btn text-uppercase">we offers</a>
                            <!-- <a href="#" class="link-btn">See All Features</a> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12" v-if="data">
                    <div class="list-of-features">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6 col-md-6" v-for="(items,index) in data[0]?.attributes?.ms_services_key_what_do_offers?.data.slice(0, 4)" :key="items?.id">
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i :class="getIcon(index)"></i>
                                    </div>
                                    <h3>{{items?.attributes?.name}}</h3>
                        <p>{{items?.attributes?.title}}</p>
                                </div>
                            </div>
                          
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServiceWeDoWhatOffer',
    props: {
        data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
},
    methods: {
      openCalendly() {
      // eslint-disable-next-line no-undef
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    },
    getIcon(index) {
        const icons = [
            'ri-eye-line',
            'ri-stack-line',
            'ri-leaf-line',
            'ri-secure-payment-line',
            'ri-cloud-line',
            'ri-pie-chart-2-line',
            // Add more icons as needed
        ];

        // Return the icon based on the index or a default icon if the index is out of bounds
        return icons[index] || 'ri-eye-line';
    },

  }
}
</script>