<template>
    <div class="blog-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog1.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Branding</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 14, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(0) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">Branding involves developing strategy to create a point of differentiation</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog2.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Agency</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 13, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(4) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">Design is a plan or specification for the construction of an object</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog3.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Marketing</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 12, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(2) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">The new minimum is a digital magazine with a header featuring a thin</router-link></h3>
                        </div>
                    </div>
                </div>
                 <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog4.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Branding</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 14, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(0) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">WordPress is open source software you can use to create a beautiful</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog5.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Agency</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 13, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(4) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">Bootstrap 5 is open source software you can use to create a beautiful</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog6.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Marketing</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 12, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(2) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">Beautiful designs, powerful features, and the freedom to build anything you</router-link></h3>
                        </div>
                    </div>
                </div>
               <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog7.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Branding</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 14, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(0) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">Branding involves developing strategy to create a point of differentiation</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog8.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Agency</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 13, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(4) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">Most designs, powerful features, and the freedom to build anything you</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link to="/" class="d-block">
                                <img src="../../assets/images/blog/blog9.jpg" alt="blog">
                            </router-link>
                            <router-link to="/blog-grid" class="tag">Marketing</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> April 12, 2021</li>
                                <li><i class="ri-message-2-line"></i> <router-link to="/">(2) Comment</router-link></li>
                            </ul>
                            <h3><router-link to="/">Branding involves developing strategy to create a point</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <div class="nav-links">
                            <span class="page-numbers current">1</span>
                            <router-link to="/blog-grid" class="page-numbers">2</router-link>
                            <router-link to="/blog-grid" class="page-numbers">3</router-link>
                            <router-link to="/blog-grid" class="next page-numbers" title="Next Page"><i class="ri-arrow-right-line"></i></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlogThreeCustom'
}
</script>