<template>
    <div>
       
       
        <!-- Start Features Area -->
        <div class="features-area ptb-100 bg-F7F7FF">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title text-uppercase  ">KEY FEATURES</span>
                    <h2>Most Probably Included Best Features Ever</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon">
                                <i class="ri-eye-line"></i>
                            </div>
                            <h3>Contact Management</h3>
                            <p> Efficiently manage and organize your contacts, track interactions, and maintain a comprehensive database for seamless customer relationship management.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg2">
                                <i class="ri-stack-line"></i>
                            </div>
                            <h3>Sales Pipeline Tracking</h3>
                            <p> Visualize your sales pipeline, track leads, opportunities, and deals at each stage, and improve sales forecasting for better decision-making.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg3">
                                <i class="ri-leaf-line"></i>
                            </div>
                            <h3>Activity Management</h3>
                            <p>Stay on top of tasks, appointments, and follow-ups with a centralized system, ensuring timely actions and efficient collaboration within your team.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg4">
                                <i class="ri-secure-payment-line"></i>
                            </div>
                            <h3>Customer Communication</h3>
                            <p>Communicate effortlessly with customers through integrated email functionality, enabling personalized,functionality and targeted messaging to nurture relationships.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg5">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>
                                Reporting and Analytics</h3>
                            <p> Gain valuable insights into your sales performance, customer behavior, and team productivity through detailed reports and analytics, helping you make data-driven decisions.
</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg6">
                                <i class="ri-pie-chart-2-line"></i>
                            </div>
                            <h3>
                                Integration Capabilities</h3>
                            <p>Seamlessly integrate with other business applications and tools, such as email clients, marketing automation platforms, and e-commerce systems, for enhanced productivity and streamlined processes.</p>
                        </div>
                    </div>
                    <!-- <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                        <div class="view-more-box">
                            <a href="#" class="default-btn">View More</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- End Features Area -->

    </div>
</template>

<script>
export default {
    name: 'KeyFeatures',
}
</script>