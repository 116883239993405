<template>
    <div class="software-integrations-area ptb-100">
        <div class="container">
            <div class="software-integrations-inner bg-gradient-color">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="software-integrations-content white-color">
                            <span class="sub-title text-uppercase  ">SOFTWARE INTEGRATIONS</span>
                            <h2>Easy & Perfect Solution With Latest Software Integrations</h2>
                            <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. never missyour chance its just began. Backup just log in with your mail account.</p>
                            <p>Most provabily best  for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy.</p>
                            <!-- <router-link to="/sign-in" class="default-btn">Get Started</router-link> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="software-integrations-list">
                            <img src="../../assets/images/shape/border.png" alt="border">
                            <ul>
                                <li data-aos="fade-down">
                                    <img src="../../assets/images/software-integrations/atlassian.png" class="atlassian" alt="atlassian">
                                </li>
                                <li data-aos="fade-right">
                                    <img src="../../assets/images/software-integrations/skype.png" class="skype" alt="skype">
                                </li>
                                <li data-aos="fade-up">
                                    <img src="../../assets/images/software-integrations/gdrive.png" class="gdrive" alt="gdrive">
                                </li>
                                <li data-aos="fade-down">
                                    <img src="../../assets/images/software-integrations/slack.png" class="slack" alt="slack">
                                </li>
                                <li data-aos="fade-up">
                                    <img src="../../assets/images/software-integrations/jira.png" class="jira" alt="jira">
                                </li>
                                <li>
                                    <img src="../../assets/images/software-integrations/frame.png" class="frame" alt="frame">
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SoftwareIntegrations'
}
</script>