<template>
    <div>
        <SignUp />
    </div>
</template>

<script>
import SignUp from '../Authentication/SignUp'

export default {
    name: 'SignUpPage',
    components: {
        SignUp,
    }
}
</script>