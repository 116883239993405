<template>
  <FormModal v-if="showPopup" :closePopup="closePopup" />

  <div>
    <Navbar :openPopup="openPopup" />
    <About />

    <Benifits />
    <ServiceTeam />

    <AppScreens />

    <IncludedBestFeatures />
    <HowItWorks />

    <BestFeaturesEver />
    <TeamTwo />

    <AppDownloadOne />
    <Partner />
    <Faq />
    <Client />


    <FooterStyleFour />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";



import Faq from "../Faq/Faq";


import TeamTwo from "../TeamTwo/Team.vue";

import Partner from "../Common/Partner";

import FooterStyleFour from "../Layout/FooterStyleFour";
import FormModal from "../HomeThree/FormModal.vue";
import About from "./About.vue";
import Benifits from './Benifits.vue'
// import Courses from './Courses.vue'
import BestFeaturesEver from './BestFeaturesEver.vue'
import AppDownloadOne from './AppDownloadOne.vue'
import AppScreens from "../HomeOne/AppScreens.vue";
import ServiceTeam from "../Pages/Homepage/ServiceTeam.vue";
import HowItWorks from '../HowItWorks/HowItWorks.vue'
import Client from "../DoersOnline/Client.vue";
import IncludedBestFeatures from "../HomeOne/IncludedBestFeatures";

export default {
  name: "MeanderTraining",
  components: {
    Navbar,

    About,

    Faq,

    HowItWorks,
    BestFeaturesEver,
    TeamTwo,

    AppDownloadOne,
    Partner,
    IncludedBestFeatures,

    FooterStyleFour,
    FormModal,

    Benifits,
    // Courses,
    AppScreens,
    ServiceTeam,
    Client
  },
  data() {
    return {
      showPopup: false
    }
  },
  methods: {
    openPopup() {
      this.showPopup = true
    },
    closePopup() {
      this.showPopup = false
    },
  }
};
</script>
