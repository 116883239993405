<template>
    <div class="app-download-area ptb-100">
        <div class="container">
            <div class="app-download-inner">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="app-download-content">
                            <span class="sub-title text-uppercase  ">Why us
</span>
                            <h2>Ignite Your Online Presence for Success
 </h2>
                            <p>From captivating content creation to effective reputation management and conversion optimization, our expert team delivers results that make a difference. Choose us  and experience the power of innovative strategies and a dedicated team driving your business forward in the digital landscape.</p>
                            <!-- <div class="btn-box">
                                <a href="#" class="playstore-btn" target="_blank">
                                    <img src="../../assets/images/play-store.png" alt="image">
                                    Get It On
                                    <span>Google Play</span>
                                </a>
                                <a href="#" class="applestore-btn" target="_blank">
                                    <img src="../../assets/images/apple-store.png" alt="image">
                                    Download on the
                                    <span>Apple Store</span>
                                </a>
                            </div> -->
                            <button @click="openCalendly" class="default-btn">Get in Touch</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="app-download-image" data-aos="fade-up">
                            <img src="../../assets/images/app/app-img3.png" alt="app-img">
                        </div>
                    </div>
                </div>
                <div class="shape5">
                    <img src="../../assets/images/shape/shape4.png" alt="shape4">
                </div>
                <div class="lines">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppDownloadOne',
    methods:{
        openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
    }
}
</script>