<template>
    <div class="gradient-funfacts-area pt-100 pb-75">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-funfacts-card">
                        <div class="icon">
                            <i class="ri-download-2-line"></i>
                        </div>
                        <p>Total Downloads</p>
                        <h3>10M</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-funfacts-card">
                        <div class="icon">
                            <i class="ri-star-fill"></i>
                        </div>
                        <p>Total Reviews</p>
                        <h3>799K</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-funfacts-card">
                        <div class="icon">
                            <i class="ri-global-line"></i>
                        </div>
                        <p>Worldwide Countries</p>
                        <h3>150+</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-funfacts-card">
                        <div class="icon">
                            <i class="ri-map-pin-user-line"></i>
                        </div>
                        <p>Active Users</p>
                        <h3>5M</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Funfacts'
}
</script>