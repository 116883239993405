<template>
    <div class="app-progress-area bg-black ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="app-progress-animation-image">
                        <img src="../../assets/images/app-progress/app-progress1.png" alt="app-progress" data-aos="fade-down" data-aos-duration="1000">
                        <img src="../../assets/images/app-progress/app-progress2.png" alt="app-progress" data-aos="fade-right" data-aos-duration="1000">
                        <img src="../../assets/images/app-progress/app-progress3.png" alt="app-progress" data-aos="fade-left" data-aos-duration="1000">
                        <img src="../../assets/images/app-progress/app-progress4.png" alt="app-progress" data-aos="fade-up" data-aos-duration="1000">
                        <img src="../../assets/images/app-progress/progress-main.png" class="main-image" alt="app-progress">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="app-progress-content text-white">
                        <span class="sub-title text-uppercase  ">{{data[0]?.attributes?.ms_technologies_progresses.data[0]?.attributes?.name||'APP PROGRESS'}}</span>
                        <h2>{{data[0]?.attributes?.ms_technologies_progresses.data[0]?.attributes?.title||'Our Journey of Innovation'}}</h2>
                        <p>{{data[0]?.attributes?.ms_technologies_progresses.data[0]?.attributes?.description||"Discover the evolution of our apps at Meander Software. We're dedicated to constant improvement and innovation to provide you with the latest in software technology. Explore our app's journey."}}</p>
                        <!-- <p>Most provabily best  for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy  backup just log in with your mail account from.</p> -->
                        <a href="#" class="default-btn">REACH US</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TechnologiesChallengesAndTrack',
    props:{
         data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
    },
    methods: {
      openCalendly() {
      // eslint-disable-next-line no-undef
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>