<template>
    <div class="features-area ptb-100 bg-F7F7FF" v-if="data">
        <div class="container">
            <div class="section-title">
                <span class="sub-title text-uppercase  ">KEY FEATURES</span>
                <h2>Most Probably Included Best Features Ever</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6" v-for="feature in data[0]?.attributes?.apps_features?.data" :key="feature?.id">
                    <div class="single-features-item">
                        <div class="icon">
                            <i class="ri-eye-line"></i>
                        </div>
                        <h3>{{feature?.attributes?.name}}</h3>
                        <p>{{feature?.attributes?.description}}</p>
                    </div>
                </div>
               
                <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                    <div class="view-more-box">
                        <a @click="openCalendly" class="default-btn">Schedule Demo</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IncludedBestFeatures',
    props: {
        data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
},
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>