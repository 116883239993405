<template>
    <div>
        <ForgetPassword />
    </div>
</template>

<script>
import ForgetPassword from '../Authentication/ForgetPassword'

export default {
    name: 'ForgetPasswordPage',
    components: {
        ForgetPassword,
    }
}
</script>