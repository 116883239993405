<template>
  
  <div>
    <div v-if="cshowPopup" class="cookies-popup">
      <div class="cookies-popup-content">
        <p class="p">This website uses cookies to ensure you get the best experience on our website.</p>
        <div>
          <button style="margin-right: 5px" @click="acceptCookies">Accept</button>
          <button  @click="cancelCookies">Cancel</button>


        </div>
      </div>

  </div>
  <FormModal v-if="showPopup" :closePopup="closePopup" />
    <Navbar :openPopup="openPopup"/>
    <MainBanner :openPopup="openPopup"/>
    <Funfacts />
    <IncludedBestFeatures />

    <Features />
    <BestFeaturesEver />
    <IndustryWeServe/>
    <!-- <Feedback /> -->
    <Partner />
    <AppScreens />
    <ChallengesAndTrack />
    <SoftwareIntegrations />
    <Screenshots />
    <!-- <Team /> -->
    <ServiceTeam :data="techData"/>
    <Blog />
    <!-- <CustomerReviews /> -->
    <AppReviews/>
    <Stats />

    <!--  <Video />
        <GettingBestApp />
        <AppDownload />
        <Pricing />
        <FreeTrial />
        <Footer /> -->
        <FooterStyleFour />
  </div>
</template>
<style>
.cookies-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #060606a1;
  color: #fff;
  text-align: center;
  padding: 10px;
  z-index: 11;
}

.cookies-popup-content {
  max-width: 600px;
  margin: 0 auto;
}
.p {
  color:#fff
}
button {
  margin: 5px;

  background: #fff;
  color: #333;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
</style>
<script>
import Navbar from "../Layout/Navbar";
import Funfacts from "../AboutOne/Funfacts.vue";
import IncludedBestFeatures from "../HomeOne/IncludedBestFeatures";
import Feedback from "../AboutTwo/Feedback";
import BestFeaturesEver from "../FeaturesOne/BestFeaturesEver";
import Partner from "../Common/Partner.vue";
import AppScreens from "../HomeOne/AppScreens";
import ChallengesAndTrack from "../AboutOne/ChallengesAndTrack";
// import SoftwareIntegrations from '../Common/SoftwareIntegrations'
import SoftwareIntegrations from "../AboutOne/SoftwareIntegrations";
import Screenshots from "../HomeSix/Screenshots";
 
import Blog from "../BlogOne/Blog";
 
import Stats from '../AboutOne/Stats.vue'

import MainBanner from "../HomeOne/MainBanner";
import Features from "../HomeOne/Features";
 
// import CustomerReviews from '../Common/CustomerReviews'
 
 import FooterStyleFour from '../Layout/FooterStyleFour'
// import ServiceTeam from "./Services/ServiceTeam";
import axios from "axios";
import AppReviews from "./AppReviews.vue";
import FormModal from "../HomeThree/FormModal.vue";
import ServiceTeam from "./Homepage/ServiceTeam.vue";
import IndustryWeServe from "../HomeOne/IndustryWeServe.vue";

export default {
  name: "HomePageOne",
  components: {
    Navbar,
    Funfacts,
    MainBanner,
    Features,
    Feedback,
    BestFeaturesEver,
    Partner,
    AppScreens,
    Screenshots,
    Blog,
    Stats,
    IncludedBestFeatures,
    ChallengesAndTrack,
    SoftwareIntegrations,
    FooterStyleFour,
    ServiceTeam,
    AppReviews,
    FormModal,
    IndustryWeServe
},
data() {
    return {
      baseUrl: 'https://content.meander.software/',
    
      techData:[],
      isLoading: false,
      showPopup: false,
      cshowPopup:true

    }
  },
async mounted() {
    await this.getData2();


  },
  methods: {
      openPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    cancelCookies() {
      this.cshowPopup = false;
    },
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', 'true');
      this.cshowPopup = false;
    },
    async getData2() {
      try {
        const response = await axios.get(`${this.$baseUrl}/ms-services?populate=ms_services_technologies_experts.image,ms_services_client_technologies.technologies`);
        const { data } = response
        this.techData = data.data;
        document.title = "Best Software Company in US & India.";

      } catch (error) {
        console.log(error);
      }
    },
    async handleNavbarLinkClick(event) {
      this.isLoading = true
      if (event?.target?.href?.includes('services')) {
        await this.getData();
      }
      
      this.isLoading = false
    },
  },
  created() {
    if (localStorage.getItem('cookiesAccepted') === 'true') {
      this.cshowPopup = false;
    }
  },
};
</script>
