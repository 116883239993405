<template>
    <div class="blog-skeleton">
      <!-- <div class="skeleton-header">
        <div class="skeleton-title"></div>
        <div class="skeleton-search"></div>
      </div> -->
      <!-- <div class="skeleton-sidebar">
        <div class="skeleton-search"></div>
        <div class="skeleton-popular-posts">
          <div class="skeleton-post" v-for="n in 5" :key="n"></div>
        </div>
        <div class="skeleton-categories">
          <div class="skeleton-category" v-for="n in 5" :key="n"></div>
        </div>
      </div> -->
      <div class="skeleton-content">
        <div class="skeleton-post" v-for="n in 1" :key="n"></div>
      </div>
    </div>
  </template>
  
  <style scoped>
  .blog-skeleton {
    display: flex;
    flex-direction: column;
  }
  
  .skeleton-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }
  
  .skeleton-title {
    width: 150px;
    height: 30px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
  }
  
  .skeleton-search {
    width: 150px;
    height: 30px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
  }
  
  .skeleton-sidebar {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }
  
  .skeleton-popular-posts {
    margin-top: 20px;
    .skeleton-post {
      height: 100px;
      margin-bottom: 10px;
      background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
      background-size: 200% 100%;
      animation: loading 1.5s infinite;
    }
  }
  
  .skeleton-categories {
    margin-top: 20px;
    .skeleton-category {
      height: 30px;
      margin-bottom: 5px;
      background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
      background-size: 200% 100%;
      animation: loading 1.5s infinite;
    }
  }
  
  .skeleton-content {
    margin-top: 100px;
    padding: 30px;

    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .skeleton-post {
      width: calc(50% - 20px);
      height: 600px;
      /* margin-right: 15px; */
      margin-bottom: 20px;
      background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
      background-size: 400% 100%;
      animation: loading 1.5s infinite;
    }
  }
  
  .skeleton-pagination {
    width: 100%;
    height: 40px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
  }
  
  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  </style>
  