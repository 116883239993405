<template>
    <div>
        <Navbar />
        <!-- <PageTitle pageTitle="Blog Details" /> -->
        <Details />
        <FooterStyleFour />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import Details from '../ResourceDetails/Details.vue'
 import FooterStyleFour from '../Layout/FooterStyleFour'

export default {
    name: 'ResourceDetails',
    components: {
        Navbar,
        PageTitle,
        Details,
        FooterStyleFour,
    }
}
</script>