<template>
    <div class="pricing-area bg-black-color ptb-100">
        <div class="container">
            <div class="section-title color-white">
                <span class="sub-title text-uppercase  ">PRICING TABLE</span>
                <h2>No Hidden Charge Applied, Choose Your Plan</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-pricing-box">
                        <div class="title">
                            <h3>Basic</h3>
                            <p>Powerful & awesome elements</p>
                        </div>
                        <div class="price">
                            $39 <span>/Month</span>
                        </div>
                        <router-link to="/contact" class="default-btn">Purchase Plan</router-link>
                        <ul class="features-list">
                            <li><i class="ri-check-line"></i> Up to 10 Website</li>
                            <li><i class="ri-check-line"></i> Lifetime free Support</li>
                            <li><i class="ri-check-line"></i> 10 GB Dedicated Hosting free</li>
                            <li><i class="ri-check-line"></i> 24/7 Support</li>
                            <li><i class="ri-close-line"></i> SEO Optimized</li>
                            <li><i class="ri-close-line"></i> Live Support</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-pricing-box active">
                        <div class="title">
                            <h3>Team</h3>
                            <p>Powerful & awesome elements</p>
                        </div>
                        <span class="popular">Most Popular</span>
                        <div class="price">
                            $49 <span>/Month</span>
                        </div>
                        <router-link to="/contact" class="default-btn">Purchase Plan</router-link>
                        <ul class="features-list">
                            <li><i class="ri-check-line"></i> Up to 200 Website</li>
                            <li><i class="ri-check-line"></i> Lifetime free Support</li>
                            <li><i class="ri-check-line"></i> 25 GB Dedicated Hosting free</li>
                            <li><i class="ri-check-line"></i> 24/7 Support</li>
                            <li><i class="ri-check-line"></i> Business Contact</li>
                            <li><i class="ri-check-line"></i> SEO Optimized</li>
                            <li><i class="ri-close-line"></i> Live Support</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-pricing-box">
                        <div class="title">
                            <h3>Business</h3>
                            <p>Powerful & awesome elements</p>
                        </div>
                        <div class="price">
                            $59 <span>/Month</span>
                        </div>
                        <router-link to="/contact" class="default-btn">Purchase Plan</router-link>
                        <ul class="features-list">
                            <li><i class="ri-check-line"></i> Up to 500 Website</li>
                            <li><i class="ri-check-line"></i> Lifetime free Support</li>
                            <li><i class="ri-check-line"></i> 50 GB Dedicated Hosting free</li>
                            <li><i class="ri-check-line"></i> 24/7 Support</li>
                            <li><i class="ri-check-line"></i> SEO Optimized</li>
                            <li><i class="ri-check-line"></i> Live Support</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="see-more-pricing color-white">
                        <router-link to="/pricing" class="link-btn">See All Pricing Plan</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape7"><img src="../../assets/images/shape/shape6.png" alt="shape"></div>
        <div class="shape8"><img src="../../assets/images/shape/shape7.png" alt="shape"></div>
    </div>
</template>

<script>
export default {
    name: 'Pricing'
}
</script>