<!-- eslint-disable no-undef -->
<template>
    <div class="app-progress-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="app-progress-image text-center">
                        <img src="../../../assets/images/app/app-img2.png" alt="app-img">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="app-progress-content">
                        <span class="sub-title text-uppercase  ">{{ data[0]?.attributes?.ms_industries_get_started_request_demos?.data[0]?.attributes?.name||"Schedule Demo" }}</span>
                        <h2>{{ data[0]?.attributes?.ms_industries_get_started_request_demos?.data[0]?.attributes?.title||"Set Up The Challenges and Track Your Progress" }}</h2>
                        <p>{{ data[0]?.attributes?.ms_industries_get_started_request_demos?.data[0]?.attributes?.description||"Most provabily best  for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy  backup just log in with your mail account from." }}</p>
                        <p></p>
                         <button @click="openCalendly" class="default-btn">Schedule Demo</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IndustriesRequestDemo',
    props:{
     data:[],
    },
   
    methods: {
      openCalendly() {
      // eslint-disable-next-line no-undef
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>