<template>
    <div class="how-it-works-area ptb-100">
        <div class="container">
            <div class="how-it-works-content">
                <div class="number">1</div>
                <div class="row m-0">
                    <div class="col-lg-3 col-md-12 p-0">
                        <div class="box">
                            <h3>Step 1</h3>
                            <span>Provide project details</span>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12 p-0">
                        <div class="content">
                            <p> Our project aims to develop a user-friendly mobile application that enhances productivity in the workplace by streamlining task management and communication among team members. The application will feature intuitive task organization, real-time collaboration, and seamless integration with popular productivity tools.</p>
                            <img src="../../assets/images/how-it-works/new/InitialRequirementFormFillup.jpg" alt="create-account">
                        </div>
                    </div>
                </div>
            </div>
            <div class="how-it-works-content">
                <div class="number">2</div>
                <div class="row m-0">
                    <div class="col-lg-3 col-md-12 p-0">
                        <div class="box">
                            <h3>Step 2</h3>
                            <span>Schedule a meeting</span>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12 p-0">
                        <div class="content">
                            <p> To discuss your project thoroughly and align our strategies with your vision, we propose scheduling a meeting at your earliest convenience. During this meeting, we can delve into the specifics of your requirements, clarify any questions or concerns, and outline a tailored solution that meets your needs and goals.</p>
                            <img src="../../assets/images/how-it-works/new/SchedleMeeting.jpg" alt="select-coin">
                        </div>
                    </div>
                </div>
            </div>
            <div class="how-it-works-content">
                <div class="number">3</div>
                <div class="row m-0">
                    <div class="col-lg-3 col-md-12 p-0">
                        <div class="box">
                            <h3>Step 3</h3>
                            <span>Agree and develop:</span>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12 p-0">
                        <div class="content">
                            <p>Following our meeting and agreement on the terms, we will commence the development process. Our team of experienced developers will work diligently to bring your concept to life, ensuring that the final product aligns with your vision and exceeds your expectations.</p>
                            <!-- <ul>
                                <li>Credit Card: <strong>Visa</strong>, <strong>MasterCard</strong>, <strong>Discover</strong>, <strong>American Express</strong>, <strong>JCB</strong>, <strong>Visa Electron</strong>. The total will be charged to your card when the order is shipped.</li>
                                <li><strong>meander_software</strong> features a Fast Checkout option, allowing you to securely save your credit card details so that you don't have to re-enter them for future purchases.</li>
                                <li>PayPal: Shop easily online without having to enter your credit card details on the website.Your account will be charged once the order is completed. To register for a PayPal account, visit the website <a href="#" target="_blank">paypal.com.</a></li>
                            </ul> -->
                            <img src="../../assets/images/how-it-works/new/Contract&Development.jpg" alt="select-payment">
                        </div>
                    </div>
                </div>
            </div>
            <div class="how-it-works-content">
                <div class="number">4</div>
                <div class="row m-0">
                    <div class="col-lg-3 col-md-12 p-0">
                        <div class="box">
                            <h3>Step 4</h3>
                            <span>Launch project</span>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12 p-0">
                        <div class="content">
                            <p>As development nears completion, we will prepare to launch and deploy your project to make it accessible to your target audience. This involves rigorous testing to ensure the application functions smoothly across various devices and platforms.</p>
                            <img src="../../assets/images/how-it-works/new/Launch&Deployment.jpg" alt="payment-method">
                        </div>
                    </div>
                </div>
            </div>
            <div class="how-it-works-content">
                <div class="number">5</div>
                <div class="row m-0">
                    <div class="col-lg-3 col-md-12 p-0">
                        <div class="box">
                            <h3>Step 5</h3>
                            <span>Market and support ongoing:</span>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12 p-0">
                        <div class="content">
                            <p>Beyond the launch, our commitment to your project does not end. We will continue to support your project through strategic marketing initiatives aimed at driving user adoption and engagement. we ensure your project's success through strategic marketing and continuous support, driving growth and success</p>
                            <img src="../../assets/images/how-it-works/new/MarketingandOngoingSupport.jpg" alt="confirm-order">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HowItWorks',
    props: {
        data: {
            type: [], // Set the prop type to be Any
            required: true,
        },
    },
}
</script>