<template>
    <div>
       
       
        <!-- Start Features Area -->
        <div class="features-area ptb-100 bg-F7F7FF">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title text-uppercase  ">Choose Doers CRM</span>
                    <h2>Empower Your Business with the Ultimate CRM Solution</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon">
                                <i class="ri-eye-line"></i>
                            </div>
                            <h3>User-Friendly Interface</h3>
                            <p> Enjoy a user-friendly and intuitive interface that requires minimal training, ensuring easy adoption and maximum productivity.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg2">
                                <i class="ri-stack-line"></i>
                            </div>
                            <h3>Customizable Features</h3>
                            <p>Tailor Doers CRM to meet your unique business needs with customizable fields, workflows, and automation, providing a personalized CRM experience.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg3">
                                <i class="ri-leaf-line"></i>
                            </div>
                            <h3>Scalability and Flexibility</h3>
                            <p> Grow your business with ease as Doers CRM offers scalability and flexibility to adapt to your changing needs and evolving customer demands.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg4">
                                <i class="ri-secure-payment-line"></i>
                            </div>
                            <h3>Superior Customer Support</h3>
                            <p>Receive prompt and reliable customer support from our dedicated team, ensuring a seamless experience and peace of mind.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg5">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>Robust Data Security</h3>
                            <p>Rest assured knowing that your valuable customer data is protected with advanced security measures, ensuring confidentiality and compliance.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg6">
                                <i class="ri-pie-chart-2-line"></i>
                            </div>
                            <h3>Integration Capabilities</h3>
                            <p>Seamlessly integrate with other essential business tools and systems, enabling data synchronization and streamlined workflows for increased efficiency.</p>
                        </div>
                    </div>
                    <!-- <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                        <div class="view-more-box">
                            <a href="#" class="default-btn">View More</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- End Features Area -->

    </div>
</template>

<script>
export default {
    name: 'WhyChooseUs',
}
</script>