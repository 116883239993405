<template>
    <div class="new-app-main-banner-wrap-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="new-app-main-banner-wrap-content">
                        <span class="sub-title text-uppercase  ">#Get Your 14 Days Free Trial</span>
                        <h1>Manage All Of Your Stuff Using A Meander Software</h1>
                        <p>Discover a powerful suite of tools designed to simplify your workflow and boost productivity.</p>

                        <ul class="user-info">
                            <li>
                                <img src="../../assets/images/user/user1.jpg" class="rounded-circle" alt="image">
                            </li>
                            <li>
                                <img src="../../assets/images/user/user2.jpg" class="rounded-circle" alt="image">
                            </li>
                            <li>
                                <img src="../../assets/images/user/user3.jpg" class="rounded-circle" alt="image">
                            </li>
                            <li>
                                <img src="../../assets/images/user/user4.jpg" class="rounded-circle" alt="image">
                            </li>
                            <li class="title">4K+ used this App</li>
                        </ul>

                        <!-- <div class="app-btn-box">
                            <a href="#" class="applestore-btn" target="_blank">
                                <img src="../../assets/images/apple-store.png" alt="image">
                                Download on the
                                <span>Apple Store</span>
                            </a>

                            <a href="#" class="playstore-btn" target="_blank">
                                <img src="../../assets/images/play-store.png" alt="image">
                                Get It On
                                <span>Google Play</span>
                            </a>
                        </div> -->
                    </div>
                </div> 
                <div class="col-lg-6 col-md-12">
                    <div class="new-app-main-banner-wrap-image" data-aos="fade-left" data-aos-duration="2000">
                        <img src="../../assets/images/more-home/banner/banner-2.png" alt="image">

                        <div class="wrap-image-shape-1">
                            <img src="../../assets/images/more-home/banner/shape-3.png" alt="image">
                        </div>
                        <div class="wrap-image-shape-2">
                            <img src="../../assets/images/more-home/banner/shape-4.png" alt="image">
                        </div>
                        <div class="banner-circle">
                            <img src="../../assets/images/more-home/banner/banner-circle.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="new-app-main-banner-wrap-shape">
            <img src="../../assets/images/more-home/banner/shape-5.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>