<template>
  <FormModal v-if="showPopup" :closePopup="closePopup" />

  <div>
    <Navbar :openPopup="openPopup" />
    <!-- <PageTitle pageTitle="Doers Online" /> -->
    <AppSummary />
    <AboutApp />
    <KeyFeatures />
    <!-- <CustomFeatures /> -->
    <ServiceTeam />
    <!-- <Technology/> -->
    <WhyChooseUs />
    <!-- <MeanderFeatures /> -->
    <!-- <AppScreen/> -->
    <AppScreens />
    <AdminPanel />
    <HowItWorks />
    <WhatDoWeOffer />
    <PlugIns />


    <Client />
    <Faq />
    <!-- <FeedbackTwo /> -->

    <!-- <Team /> -->
    <!-- <Screenshots /> -->
    <!-- <HowItWorks /> -->
    <!-- <BestFeaturesEver /> -->
    <!-- <TeamTwo /> -->
    <!-- <Video /> -->
    <AppDownload />

    <!-- <AppDownloadOne /> -->
    <!-- <Partner /> -->


    <ContactInfo />
    <!-- <Gallery /> -->
    <iframe frameborder="0" style="height:500px;width:99%;border:none;" src='https://forms.zohopublic.in/meandersoftwarecontact/form/ClientNeedsAssessmentForm/formperma/4fjH7ZBQUI7rdg6ZqA85n4ldQD4ajNkC_-RXMdrXXPs'></iframe>


    <FooterStyleFour />
  </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
// import PageTitle from '../Common/PageTitle'


import Faq from "../Faq/Faq"


import ContactInfo from "./ContactInfo.vue"
import AppDownload from "../Common/AppDownload"

 



// import About from '../AboutOne/About'
// import Funfacts from '../AboutOne/Funfacts'
// import ChallengesAndTrack from '../AboutOne/ChallengesAndTrack'
// import Stats from '../AboutOne/Stats'
// import SoftwareIntegrations from '../AboutOne/SoftwareIntegrations'
// import OurTeam from '../AboutOne/OurTeam'
// import Partner from '../Common/Partner'
import FooterStyleFour from '../Layout/FooterStyleFour'
import AppSummary from './AppSummary.vue';
import AboutApp from './AboutApp.vue';

import AdminPanel from "./AdminPanel.vue"
import HowItWorks from "../HowItWorks/HowItWorks.vue"
import WhatDoWeOffer from './WhatDoWeOffer.vue'
import PlugIns from './PlugIns.vue'
import Client from './Client.vue'
import KeyFeatures from './KeyFeatures.vue';
import WhyChooseUs from './WhyChooseUs.vue';
import FormModal from '../HomeThree/FormModal.vue';
import ServiceTeam from '../Pages/Homepage/ServiceTeam.vue';
import AppScreens from '../HomeOne/AppScreens.vue';








export default {
  name: 'DoersOnline',
  components: {
    Navbar,
    // PageTitle,


    Faq,

    HowItWorks,

    // ContactInfo,
    AppDownload,
 
    FooterStyleFour,
    AppSummary,
    AboutApp,

    AdminPanel,
    WhatDoWeOffer,
    PlugIns,
    Client,
    ContactInfo,
    KeyFeatures,
    WhyChooseUs,
    FormModal,
    ServiceTeam,
    AppScreens
  },
  data() {
    return {
      showPopup: false
    }
  },
  methods: {
    openPopup() {
      this.showPopup = true
    },
    closePopup() {
      this.showPopup = false
    },
  }
}
</script>