<template>
    <div>
       
       
        <!-- Start Features Area -->
        <div class="features-area ptb-100 bg-F7F7FF">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title text-uppercase  ">KEY FEATURES</span>
                    <h2>Most Probably Included Best Features Ever</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg6">
                                <i class="ri-pie-chart-2-line"></i>
                            </div>
                            <h3>Analytics and Reporting:</h3>
                            <p> Gain valuable insights into your digital marketing efforts, measure performance, and make data-driven decisions to optimize your strategies and achieve better results.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg2">
                                <i class="ri-stack-line"></i>
                            </div>
                            <h3>Search Engine Marketing (SEM)</h3>
                            <p>Harness the power of paid search advertising to reach your target audience, generate leads, and boost conversions.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg3">
                                <i class="ri-leaf-line"></i>
                            </div>
                            <h3>Social Media Marketing (SMM)</h3>
                            <p>Engage and connect with your audience through strategic social media campaigns, driving brand awareness and fostering customer loyalty.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg4">
                                <i class="ri-secure-payment-line"></i>
                            </div>
                            <h3>Content Marketing</h3>
                            <p>Deliver valuable and relevant content that resonates with your audience, attracting, engaging, and converting prospects into customers.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg5">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>  Email Marketing</h3>
                            <p>Nurture customer relationships, promote your products or services, and drive conversions through personalized and targeted email campaigns.
</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        
                        <div class="single-features-item">
                            <div class="icon">
                                <i class="ri-eye-line"></i>
                            </div>
                            <h3>Search Engine Optimization (SEO)</h3>
                            <p>Improve your website's visibility and organic search rankings to drive targeted traffic and increase online visibility.</p>
                        </div>
                    </div>
                    <!-- <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                        <div class="view-more-box">
                            <a href="#" class="default-btn">View More</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- End Features Area -->

    </div>
</template>

<script>
export default {
    name: 'KeyFeatures',
}
</script>