<template>
  <FormModal v-if="showPopup" :closePopup="closePopup" />

  <div>
    <Navbar :openPopup="openPopup" />
    <PageTitle2 :data="{ pageTitle: 'Become A Partner' }" />
    <!-- <BecomePartner />
    <Contact /> -->
    <Featuresist />
    <!-- <Features /> -->
    <Funfacts />
    <!-- <TeamTwo /> -->
    <!-- <Team /> -->
    <ServiceTeam :data="technologies" />
    <!-- <TechnoloyWeexpert :data="technologies"/> -->
    <Blog />
    <!-- <Screenshots /> -->
    <SoftwareIntegrations />
    <Faq />
    <FooterStyleFour />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
import PageTitle2 from "../Common/PageTitle2.vue";
import BecomePartner from "../BecomePartner/BecomePartner";
import Contact from "../BecomePartner/Contact.vue";
import Featuresist from "../BecomePartner/Featuresist.vue";

import Features from "../HomeEight/Features";
import TeamTwo from "../TeamTwo/Team.vue";
import Blog from "../BlogOne/Blog";
import Team from "../TeamOne/Team.vue";
import Screenshots from "../HomeFour/Screenshots";
import SoftwareIntegrations from "../AboutOne/SoftwareIntegrations";
import Faq from "../Faq/Faq";
import FooterStyleFour from "../Layout/FooterStyleFour";
import TechnoloyWeexpert from "./BecomePatner/TechnoloyWeexpert.vue";
import axios from "axios";
import FormModal from "../HomeThree/FormModal.vue";
import ServiceTeam from "./Homepage/ServiceTeam.vue";
import Funfacts from "../AboutOne/Funfacts.vue";

export default {
  name: "BecomePartnerPage",
  components: {
    Navbar,
    PageTitle2,
    BecomePartner,
    Contact,
    Features,
    TeamTwo,
    Team,
    Blog,
    Screenshots,
    SoftwareIntegrations,
    Faq,
    FooterStyleFour,
    TechnoloyWeexpert,
    FormModal,
    Featuresist,
    ServiceTeam,
    Funfacts
  },
  data() {
    return {
      baseUrl: "https://content.meander.software/",
      // techData: [],
      technologies: [],
      isLoading: false,
      showPopup: false,
    };
  },
  async mounted() {
    // await this.getData2();

    await this.gettitle();
    await this.getData();
  },

  methods: {
    openPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    async gettitle() {
      document.title = "Become a Partner";
    },
    async getData() {
      try {
        const response = await axios.get(
          `${this.$baseUrl}/ms-services?populate=ms_services_technologies_experts.image,ms_services_client_technologies.technologies`
        );
        const { data } = response;
        this.technologies = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    // async getData() {
    //   try {
    //     const response = await axios.get(
    //       `${this.$baseUrl}/ms-services-technologies-experts?populate=*`
    //     );
    //     const { data } = response;
    //     this.technologies = data.data;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // async getData2() {
    //   try {
    //     const response = await axios.get(
    //       `${this.$baseUrl}/ms-services?populate=ms_services_technologies_experts.image,ms_services_client_technologies.technologies`
    //     );
    //     const { data } = response;
    //     this.techData = data.data;
    //     document.title = "Best Software Company in US & India.";
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
};
</script>
