<template>
    <div>
        <Navbar class="meander_software-new-navbar-area" />
        <MainBanner />
        <Features />
        <About />
        <IncludedBestFeatures />
        <ChallengesAndTrack />
        <AppScreens />
        <GettingBestApp />
        <!-- <Video /> -->
        <Pricing />
        <AppDownload />
        <SoftwareIntegrations />
        <CustomerReviews />
        <FreeTrial />
        <FooterStyleThree />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import MainBanner from '../HomeSeven/MainBanner'
import Features from '../HomeSeven/Features'
import About from '../HomeSeven/About'
import IncludedBestFeatures from '../HomeSeven/IncludedBestFeatures'
import ChallengesAndTrack from '../HomeSeven/ChallengesAndTrack'
import AppScreens from '../HomeSeven/AppScreens'
import GettingBestApp from '../HomeSeven/GettingBestApp'
import Pricing from '../HomeSeven/Pricing'
import AppDownload from '../HomeSeven/AppDownload'
import SoftwareIntegrations from '../HomeSeven/SoftwareIntegrations'
import CustomerReviews from '../HomeSeven/CustomerReviews'
import FreeTrial from '../HomeSeven/FreeTrial'
import FooterStyleThree from '../Layout/FooterStyleThree'

export default {
    name: 'HomePageSeven',
    components: {
        Navbar,
        MainBanner,
        Features,
        About,
        IncludedBestFeatures,
        ChallengesAndTrack,
        AppScreens,
        GettingBestApp,
        Pricing,
        AppDownload,
        SoftwareIntegrations,
        CustomerReviews,
        FreeTrial,
        FooterStyleThree,
    }
}
</script>