<template>
    <div>
        <SignIn />
    </div>
</template>

<script>
import SignIn from '../Authentication/SignIn'

export default {
    name: 'SignInPage',
    components: {
        SignIn,
    }
}
</script>