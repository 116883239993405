<template>
  <div class="team-area pt-100 pb-75" >
    <div class="section-title">
                <h2>Technologies We are Expert in</h2>
            </div>
    
    <div class="container">
      <div class="row justify-content-center">
        <div
          class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4"
          v-for="technlology in technologies[0]?.attributes?.technologies?.data"
          :key="technlology?.id"
        >
          <div class="single-team-box">
            <!-- <div class="image"> -->
            <div>
              <img
                :src="
                  baseUrl + technlology?.attributes?.logo?.data?.attributes?.url
                "
              />
            </div>
            <!-- </div> -->
            <div class="content">
              <!-- <h3>{{ technlology?.attributes?.name }}</h3> -->
              <!-- <span>Client since 2018</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ServiceTeam",
  props:{
     data:[],
    },
  data() {
    return {
      baseUrl: "https://content.meander.software/",
      technologies: [],
    };
  },
  methods: {
    async getTechnologies() {
     
     
        try {
          // const slug = this.$route.params.slug;
          // Fetch best features data from Strapi API
          const response = await axios.get(`${this.$baseUrl}/apps?populate=*&populate=technologies.logo`);

          const { data      } = response;
          this.technologies = data.data;
        } catch (error) { console.log(error);}
      
    },
  },
  mounted: async function () {
    await this.getTechnologies();
    this.isLoading = false;
  },
};
</script>
