<template>
    <div class="about-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <span class="sub-title text-uppercase  ">ABOUT US</span>
                        <h2>Most Probably You Are Getting Best App Ever</h2>
                        <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy text. Never missyour chance its just began.</p>
                        <div class="features-text">
                            <h6>Trusted and Reliable</h6>
                            <p>Most provabily best you can trust on it, just log in with your mail account from play store and using whatever you want for your business.</p>
                        </div>
                        <div class="features-text">
                            <h6>Cloud Storage</h6>
                            <p>Cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose orem ipsum dummy of your business purpose text.</p>
                        </div>
                        <!-- <div class="btn-box">
                            <router-link to="/sign-up" class="default-btn">Schedule Demo</router-link>
                            <router-link to="/features-one" class="link-btn">See All Features</router-link>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-image">
                        <img src="../../assets/images/app/app-img7.png" data-aos="fade-up" alt="about">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GettingBestApp',
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>