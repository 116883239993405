<template>
    <div class="app-progress-area bg-black ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="app-progress-animation-image">
                        <img src="../../../assets/images/app-progress/app-progress1.png" alt="app-progress" data-aos="fade-down" data-aos-duration="1000">
                        <img src="../../../assets/images/app-progress/app-progress2.png" alt="app-progress" data-aos="fade-right" data-aos-duration="1000">
                        <img src="../../../assets/images/app-progress/app-progress3.png" alt="app-progress" data-aos="fade-left" data-aos-duration="1000">
                        <img src="../../../assets/images/app-progress/app-progress4.png" alt="app-progress" data-aos="fade-up" data-aos-duration="1000">
                        <img src="../../../assets/images/app-progress/progress-main.png" class="main-image" alt="app-progress">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="app-progress-content text-white">
                       
                        <span class="sub-title text-uppercase  ">{{ data[0]?.attributes?.ms_industries_abouts?.data[0]?.attributes?.name||"" }}</span>
                        <h2>{{ data[0]?.attributes?.ms_industries_abouts?.data[0]?.attributes?.title || "Customized  Solutions for Your Business Growth" }}</h2>
                        <p>{{ data[0]?.attributes?.ms_industries_abouts?.data[0]?.attributes?.description || "Our team of experienced developers specializes in building high-quality, scalable, and feature-rich ecommerce websites and mobile apps that provide a seamless shopping experience to customers. We work closely with our clients to understand their business goals and requirements, and we tailor our solutions accordingly. We also provide comprehensive maintenance and support services to ensure that our clients' ecommerce websites and mobile apps are always up and running smoothly. Our goal is to help businesses achieve their growth targets and provide a superior shopping experience to their customers. With our customized retail and ecommerce solutions, businesses can stay ahead of the competition and drive success in the ever-changing retail landscape"}}</p>
                        <!-- <a href="#" class="default-btn">Schedule Demo</a> -->
                        <button @click="openCalendly" class="default-btn">Get Started </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IndustriesAbout',
    props:{
     data:[],
    },
   
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    },

  }
}
</script>