<template>
    <div class="error-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="error-content">
                        <img src="../../assets/images/error.png" alt="image">
                        <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                        <router-link to="/" class="default-btn">Back To Home</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>