<template>
    <div>
        <Navbar/>
        <PageTitle :data="{ pageTitle: 'Success-stories' }"  />
      <SuccessStories />
        <FooterStyleFour />
    </div>
</template>

<script>
import Navbar from '../../Layout/Navbar'
import PageTitle from '../../Common/PageTitle.vue'
import SuccessStories from './SuccesStories.vue'
 import FooterStyleFour from '../../Layout/FooterStyleFour'

export default {
    name: 'SuccessStoriesPage',
    components: {
        Navbar,
        PageTitle,
        SuccessStories,
    
        FooterStyleFour,
    }
}
</script>