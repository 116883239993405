<template>
    <div class="blog-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="blog-details-desc">
                        <div class="article-image">
                            <router-link to="/blog-grid" class="tag">Branding</router-link>
                            <img src="../../assets/images/blog/blog1.jpg" alt="blog-details">
                        </div>
                        <div class="article-content">
                            <div class="entry-meta">
                                <ul>
                                    <li><i class="ri-calendar-2-line"></i>March 14, 2021</li>
                                    <li><i class="ri-message-2-line"></i><router-link to="/blog-grid">(4) Comments</router-link></li>
                                </ul>
                            </div>
                            <h4>Branding involves developing strategy to create a pin point of differentiation the marketing</h4>
                            <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur <strong>adipisicing</strong> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea <a href="#">commodo</a> consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                            <ol>
                                <li>Nunc, mauris ut in vestibulum. Consectetur phasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam in </li>
                                <li>Most of the designer are very creative to do something , mauris ut in vestibulum. Consectetur phasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam in lobortis </li>
                                <li>There are two thing is very important in Consectetur phasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam.</li>
                                <li>Web design and development very creative to do something , mauris ut in vestibulum. Consectetur phasellus ultrices fusce nibh  venenatis, amet to all design and development.</li>
                            </ol>
                            <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>
                            <ul class="wp-block-gallery columns-3">
                                <li class="blocks-gallery-item">
                                    <figure>
                                        <img src="../../assets/images/blog/blog5.jpg" alt="image">
                                    </figure>
                                </li>
                                <li class="blocks-gallery-item">
                                    <figure>
                                        <img src="../../assets/images/blog/blog6.jpg" alt="image">
                                    </figure>
                                </li>
                                <li class="blocks-gallery-item">
                                    <figure>
                                        <img src="../../assets/images/blog/blog7.jpg" alt="image">
                                    </figure>
                                </li>
                            </ul>
                            <h4>Four major elements that we offer:</h4>
                            <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>
                            <ul>
                                <li>Scientific skills for getting a better result</li>
                                <li>Communication skills to getting in touch</li>
                                <li>A career overview opportunity available</li>
                                <li>A good work environment for work</li>
                            </ul>
                            <h4>Setting the mood with incense</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                            <blockquote class="wp-block-quote">
                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                <cite>Tom Cruise</cite>
                            </blockquote>
                            <h4>The rise of marketing and why you need it</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur <strong>adipisicing</strong> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea <a href="#">commodo</a> consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                        </div>
                        <div class="article-footer">
                            <div class="post-author-meta">
                                <div class="d-flex align-items-center">
                                    <img src="../../assets/images/user/user6.jpg" alt="user">
                                    <div class="title">
                                        <span class="name">By <router-link to="/blog-grid">EnvyTheme</router-link></span>
                                        <span class="date">March 17, 2021</span>
                                    </div>
                                </div>
                            </div>
                            <div class="article-share">
                                <ul class="social">
                                    <li><span>Share:</span></li>
                                    <li><a href="#" class="facebook" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                                    <li><a href="#" class="twitter" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                                    <li><a href="#" class="linkedin" target="_blank"><i class="ri-twitter-fill"></i></a></li>
                                    <li><a href="#" class="instagram" target="_blank"><i class="ri-instagram-line"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="related-post">
                            <h3 class="title">Related Post</h3>
                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="single-blog-post">
                                        <div class="image">
                                            <router-link to="/" class="d-block">
                                                <img src="../../assets/images/blog/blog2.jpg" alt="blog">
                                            </router-link>
                                            <router-link to="/blog-grid" class="tag">Branding</router-link>
                                        </div>
                                        <div class="content">
                                            <ul class="meta">
                                                <li><i class="ri-time-line"></i> April 14, 2021</li>
                                                <li><i class="ri-message-2-line"></i> <router-link to="/">(0) Comment</router-link></li>
                                            </ul>
                                            <h3><router-link to="/">Branding involves developing strategy to create a point of differentiation</router-link></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="single-blog-post">
                                        <div class="image">
                                            <router-link to="/" class="d-block">
                                                <img src="../../assets/images/blog/blog3.jpg" alt="blog">
                                            </router-link>
                                            <router-link to="/blog-grid" class="tag">Agency</router-link>
                                        </div>
                                        <div class="content">
                                            <ul class="meta">
                                                <li><i class="ri-time-line"></i> April 13, 2021</li>
                                                <li><i class="ri-message-2-line"></i> <router-link to="/">(4) Comment</router-link></li>
                                            </ul>
                                            <h3><router-link to="/">Design is a plan or specification for the construction of an object</router-link></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="comments-area">
                            <h3 class="comments-title">2 Comments:</h3>
                            <ol class="comment-list">
                                <li class="comment">
                                    <div class="comment-body">
                                        <footer class="comment-meta">
                                            <div class="comment-author vcard">
                                                <img src="../../assets/images/user/user1.jpg" class="avatar" alt="user">
                                                <b class="fn">John Jones</b>
                                            </div>
                                            <div class="comment-metadata">
                                                <span>January 01, 2021 at 10:59 am</span>
                                            </div>
                                        </footer>
                                        <div class="comment-content">
                                            <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                        </div>
                                        <div class="reply">
                                            <router-link to="/" class="comment-reply-link">Reply</router-link>
                                        </div>
                                    </div>
                                    <ol class="children">
                                        <li class="comment">
                                            <div class="comment-body">
                                                <footer class="comment-meta">
                                                    <div class="comment-author vcard">
                                                        <img src="../../assets/images/user/user2.jpg" class="avatar" alt="user">
                                                        <b class="fn">Steven Smith</b>
                                                    </div>
                                                    <div class="comment-metadata">
                                                        <span>January 02, 2021 at 21:59 am</span>
                                                    </div>
                                                </footer>
                                                <div class="comment-content">
                                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                </div>
                                                <div class="reply">
                                                    <a href="#" class="comment-reply-link">
                                                        Reply
                                                    </a>
                                                </div>
                                            </div>
                                            <ol class="children">
                                                <li class="comment">
                                                    <div class="comment-body">
                                                        <footer class="comment-meta">
                                                            <div class="comment-author vcard">
                                                                <img src="../../assets/images/user/user3.jpg" class="avatar" alt="user">
                                                                <b class="fn">Sarah Taylor</b>
                                                            </div>
                                                            <div class="comment-metadata">
                                                                <span>January 03, 2021 at 05:59 am</span>
                                                            </div>
                                                        </footer>
                                                        <div class="comment-content">
                                                            <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                        </div>
                                                        <div class="reply">
                                                            <a href="#" class="comment-reply-link">Reply</a>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li class="comment">
                                    <div class="comment-body">
                                        <footer class="comment-meta">
                                            <div class="comment-author vcard">
                                                <img src="../../assets/images/user/user4.jpg" class="avatar" alt="user">
                                                <b class="fn">John Doe</b>
                                            </div>
                                            <div class="comment-metadata">
                                                <span>January 04, 2021 at 05:59 am</span>
                                            </div>
                                        </footer>
                                        <div class="comment-content">
                                            <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                        </div>
                                        <div class="reply">
                                            <a href="#" class="comment-reply-link">Reply</a>
                                        </div>
                                    </div>
                                    <ol class="children">
                                        <li class="comment">
                                            <div class="comment-body">
                                                <footer class="comment-meta">
                                                    <div class="comment-author vcard">
                                                        <img src="../../assets/images/user/user1.jpg" class="avatar" alt="user">
                                                        <b class="fn">James Anderson</b>
                                                    </div>
                                                    <div class="comment-metadata">
                                                        <span>January 05, 2021 at 04:59 am</span>
                                                    </div>
                                                </footer>
                                                <div class="comment-content">
                                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                </div>
                                                <div class="reply">
                                                    <a href="#" class="comment-reply-link">Reply</a>
                                                </div>
                                            </div>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <div class="comment-respond">
                                <h3 class="comment-reply-title">Leave A Reply</h3>
                                <form class="comment-form">
                                    <p class="comment-notes">
                                        <span id="email-notes">Your email address will not be published.</span>
                                        Required fields are marked <span class="required">*</span>
                                    </p>
                                    <p class="comment-form-author">
                                        <label>Name <span class="required">*</span></label>
                                        <input type="text" id="author" placeholder="Your Name*" name="author" required="required">
                                    </p>
                                    <p class="comment-form-email">
                                        <label>Email <span class="required">*</span></label>
                                        <input type="email" id="email" placeholder="Your Email*" name="email" required="required">
                                    </p>
                                    <p class="comment-form-url">
                                        <label>Website</label>
                                        <input type="url" id="url" placeholder="Website" name="url">
                                    </p>
                                    <p class="comment-form-comment">
                                        <label>Comment</label>
                                        <textarea name="comment" id="comment" cols="45" placeholder="Your Comment..." rows="5" maxlength="65525" required="required"></textarea>
                                    </p>
                                    <p class="comment-form-cookies-consent">
                                        <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent">
                                        <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                                    </p>
                                    <p class="form-submit">
                                        <input type="submit" name="submit" id="submit" class="submit" value="Post A Comment">
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <aside class="widget-area">
                        <div class="widget widget_search">
                            <form class="search-form">
                                <label><input type="search" class="search-field" placeholder="Search..."></label>
                                <button type="submit"><i class="ri-search-2-line"></i></button>
                            </form>
                        </div>
                        <div class="widget widget_meander_software_posts_thumb">
                            <h3 class="widget-title">Popular Posts</h3>
                            <article class="item">
                                <router-link to="/" class="thumb"><span class="fullimage cover bg1" role="img"></span></router-link>
                                <div class="info">
                                    <h4 class="title usmall"><router-link to="/">Being The Best-Selling Smart Phone This Year</router-link></h4>
                                    <span class="date"><i class="ri-calendar-2-fill"></i> Jan 15, 2020</span>
                                </div>
                            </article>
                            <article class="item">
                                <router-link to="/" class="thumb"><span class="fullimage cover bg2" role="img"></span></router-link>
                                <div class="info">
                                    <h4 class="title usmall"><router-link to="/">Love Songs Helped Me Through Heartbreak</router-link></h4>
                                    <span class="date"><i class="ri-calendar-2-fill"></i> Jan 14, 2020</span>
                                </div>
                            </article>
                            <article class="item">
                                <router-link to="/" class="thumb"><span class="fullimage cover bg3" role="img"></span></router-link>
                                <div class="info">
                                    <h4 class="title usmall"><router-link to="/">Two Fashion Designers Busy With 2020 Winter Fashion</router-link></h4>
                                    <span class="date"><i class="ri-calendar-2-fill"></i> Jan 13, 2020</span>
                                </div>
                            </article>
                            <article class="item">
                                <router-link to="/" class="thumb"><span class="fullimage cover bg4" role="img"></span></router-link>
                                <div class="info">
                                    <h4 class="title usmall"><router-link to="/">Working in the Office is a Tradition For Women</router-link></h4>
                                    <span class="date"><i class="ri-calendar-2-fill"></i> Jan 12, 2020</span>
                                </div>
                            </article>
                        </div>
                        <div class="widget widget_categories">
                            <h3 class="widget-title">Categories</h3>
                            <ul>
                                <li><router-link to="/blog-right-sidebar">Business <span class="post-count">(2)</span></router-link></li>
                                <li><router-link to="/blog-right-sidebar">Privacy <span class="post-count">(5)</span></router-link></li>
                                <li><router-link to="/blog-right-sidebar">Technology <span class="post-count">(6)</span></router-link></li>
                                <li><router-link to="/blog-right-sidebar">Tips <span class="post-count">(2)</span></router-link></li>
                                <li><router-link to="/blog-right-sidebar">Log in <span class="post-count">(1)</span></router-link></li>
                                <li><router-link to="/blog-right-sidebar">Uncategorized <span class="post-count">(1)</span></router-link></li>
                            </ul>
                        </div>
                        <div class="widget widget_archive">
                            <h3 class="widget-title">Archives</h3>
                            <ul>
                                <li><router-link to="/blog-right-sidebar">May 2020 (1)</router-link></li>
                                <li><router-link to="/blog-right-sidebar">April 2020 (2)</router-link></li>
                                <li><router-link to="/blog-right-sidebar">June 2020 (3)</router-link></li>
                            </ul>
                        </div>
                        <div class="widget widget_tag_cloud">
                            <h3 class="widget-title">Tags</h3>
                            <div class="tagcloud">
                                <router-link to="/blog-right-sidebar">Advertisment</router-link>
                                <router-link to="/blog-right-sidebar">Business</router-link>
                                <router-link to="/blog-right-sidebar">Life</router-link>
                                <router-link to="/blog-right-sidebar">Lifestyle</router-link>
                                <router-link to="/blog-right-sidebar">Fashion</router-link>
                                <router-link to="/blog-right-sidebar">Ads</router-link>
                                <router-link to="/blog-right-sidebar">Inspiration</router-link>
                                <router-link to="/blog-right-sidebar">Blog</router-link>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlogDetails'
}
</script>