<template>
    <div>
        <Navbar class="navbar-style-three" />
        <!-- <PageTitle class="bg-color" pageTitle="Blog Left Sidebar" /> -->
        <BlogThreeCustom />
        <FooterStyleFour />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import BlogThreeCustom from '../BlogThreeCustom/BlogThreeCustom'
 import FooterStyleFour from '../Layout/FooterStyleFour'

export default {
    name: 'BlogPageThreeCustom',
    components: {
        Navbar,
        PageTitle,
        BlogThreeCustom,
        FooterStyleFour,
    }
}
</script>