<template>
    <div class="testimonials-area ptb-100" v-if="data && data[0]?.attributes?.ms_services_industries_we_serves?.data?.length" >
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <div class="testimonials-image">
                        <img src="../../../assets/images/backimg.png" alt="testimonials">
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="testimonials-content">
                        <span class="sub-title text-uppercase   text-uppercase">Industries we Serve</span>
                     
                    </div>
                    <div class="testimonials-slides">
                        <carousel
                            :wrap-around="true" 
                            :autoplay="5000"
                            :settings='settings'
                        >
                            <slide 
                                v-for="slide in data[0]?.attributes?.ms_services_industries_we_serves?.data" 
                                :key="slide.id"
                            >
                                <div class="content">
                                    <h3>{{slide?.attributes?.name}}</h3>
                                    <span>{{slide?.attributes?.position}}</span>
                                    <p>{{slide?.attributes?.description}}</p>
                                </div>
                            </slide>

                            <template #addons>
                                <navigation>
                                    <template #next>
                                        <i class='ri-arrow-right-s-line'></i>
                                    </template>
                                    <template #prev>
                                        <i class='ri-arrow-left-s-line'></i>
                                    </template>
                                </navigation>
                            </template>
                        </carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'ServiceFeedback',
    props: {
        data: {
      type: [], // Set the prop type to be Any
      required: true,
    },
},
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                name: 'Londynn Vargas',
                position: 'Spotify Developer',
                description: 'Meander Software is a top-notch digital agency that combines the best of both worlds - the agility of a small outsourcing company and the quality of a larger firm. Highly recommended!',
            },
            {
                id: 2,
                name: 'James Andy',
                position: 'PHP Developer',
                description: 'Choosing Meander Software was the best decision for our digital initiatives. Their tailored solutions and flexibility have exceeded our expectations.',
            },
            {
                id: 3,
                name: 'Sarah Taylor',
                position: 'JavaScript Developer',
                description: 'Impressed by Meander Software dedication, creativity, and attention to detail. They deliver high-quality solutions with speed and agility.',
            },
        ],
    }),
})
</script>