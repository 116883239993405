<template>
    <div class="features-area pt-100 pb-75">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">
                        <h1 class="fs-1">Contact us</h1>

                        <span class="sub-title text-uppercase   fw-bold"><i class="ri-mail-fill"></i> Email</span>
                        <h4>hi@meander.software</h4>
                        <span class="sub-title text-uppercase      fw-bold"><i class="ri-phone-fill"></i> Phone Number</span>
                        <h4>(+91) 80-9054-9054</h4>
                        <!-- <div class="btn-box">
                            <router-link to="/pricing" class="default-btn">Schedule Demo</router-link>
                            <router-link to="/features-two" class="link-btn">See All Features</router-link>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 features-inner-list">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="features-inner-card">
                                <div class="icon">
                                    <i class="ri-map-pin-line"></i>


                                    <h3>India Head Office:</h3>
                                </div>
                                <p>A-230, First Floor, Sector 8 Dwarka,</p>
                                <p>Dwarka, New Delhi, Delhi 110077</p>

                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="features-inner-card with-box-shadow">
                                <div class="icon">
                                    <i class="ri-map-pin-line"></i>

                                    
                                    <h3>USA Head Office</h3>
                                </div>
                                <p>98-61 Corona Ave. Queens,</p>
                                <p>New York 11368</p>

                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6 mt-1">
                            <div class="features-inner-card with-box-shadow">
                                <div class="icon">
                                    <i class="ri-map-pin-line"></i>
                                    
                                    <h3>USA Satellite Suite</h3>
                                </div>
                                <p>1032 E BRANDON BLVD</p>
                                <p>#3432 BRANDON, FL 33511</p>

                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="features-inner-card">
                                <div class="icon">
                                    <i class="ri-map-pin-line"></i>

                                    
                                    <h3>Development Center</h3>
                                </div>
                                <p>Tdi Business Centre, 11th Floor,</p>
                                <p>Sector 118, Mohali, Punjab 160055.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Address',
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>