<template>
    
    <div  class="blog-area ptb-100" >
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <aside class="widget-area left-sidebar">
                        <div class="widget widget_search">
                            <form class="search-form">
                                <label><input type="search" class="search-field" placeholder="Search..."></label>
                                <button type="submit"><i class="ri-search-2-line"></i></button>
                            </form>
                        </div>
                        <div class="widget widget_meander_software_posts_thumb">
                            <h3 class="widget-title">Popular Posts</h3>
                            <article class="item" v-for="(blog) in popularBlogs?.data" :key="blog.id">
                                <router-link :to="`/resource-details/${blog.attributes.slug}`"  class="thumb"><span class="fullimage cover"
                                        :style="{ backgroundImage: 'url(' + baseUrl + blog.attributes.image.data.attributes.url + ')' }"
                                        role="img"></span></router-link>
                                <div class="info">
                                    <h4 class="title usmall"><router-link :to="`/resource-details/${blog.attributes.slug}`" >{{ blog.attributes.title
                                    }}</router-link></h4>
                                    <span class="date"><i class="ri-calendar-2-fill"></i>{{
                                        formatDate(blog.attributes.updatedAt) }}</span>
                                </div>
                            </article>

                        </div>
                        <div class="widget widget_categories">
                            <h3 class="widget-title">Categories</h3>
                            <ul>
                                <li v-for="(category) in categories" :key="category.id">
                                    <router-link :to="{path:'/resources',query:{slug:category.attributes.slug}}" >
                                    <a class="cursor-pointer ">{{ category.attributes.name }}
                                        <!-- <span class="post-count">(2)</span> -->
                                    </a>
                                </router-link>
                                </li>
                            </ul>
                        </div>
                        <!-- <div class="widget widget_archive">
                            <h3 class="widget-title">Archives</h3>
                            <ul>
                                <li><a href="#">May 2020 (1)</a></li>
                                <li><a href="#">April 2020 (2)</a></li>
                                <li><a href="#">June 2020 (3)</a></li>
                            </ul>
                        </div>
                        <div class="widget widget_tag_cloud">
                            <h3 class="widget-title">Tags</h3>
                            <div class="tagcloud">
                                <a href="#">Advertisment</a>
                                <a href="#">Business</a>
                                <a href="#">Life</a>
                                <a href="#">Lifestyle</a>
                                <a href="#">Fashion</a>
                                <a href="#">Ads</a>
                                <a href="#">Inspiration</a>
                                <a href="#">Blog</a>
                            </div>
                        </div> -->
                    </aside>
                </div>
                <div class="col-lg-8 col-md-12" >
                    <div class="row justify-content-center">
                        <BlogSkleton v-if="isLoading"/>
                        <div v-else class="col-lg-6 col-md-6" v-for="(blog) in blogs" :key="blog.id">
                            <div class="single-blog-post">
                                <div class="image">
                                    <router-link :to="`/resource-details/${blog.attributes.slug}`"  class="d-block">
                                        <img style="height:250px;  "  :src="baseUrl + blog.attributes.image.data.attributes.url" alt="blog">
                                        <!-- <img  :src="blog.blog_img.url" alt="blog"> -->
                                    </router-link>
                                    <!-- <router-link :to="`/resource-details/${blog.attributes.slug}`"  class="tag">{{
                                        blog.attributes.blog_categories.data[0].attributes.name
                                    }}</router-link> -->
                                </div>
                                <div class="content">
                                    <ul class="meta">
                                        <li><i class="ri-time-line"></i> {{ formatDate(blog.attributes.updatedAt) }}</li>
                                        <!-- <li><i class="ri-message-2-line"></i> <router-link to="/resource-details">(0) Comment</router-link></li> -->
                                    </ul>
                                    <h3><router-link :to="`/resource-details/${blog.attributes.slug}`" >{{ blog.attributes.title }}</router-link></h3>
                                </div>
                            </div>
                        </div>







                        <div class="col-lg-12 col-md-12">
                            <div class="pagination-area">
                                <!-- <div class="nav-links">
                                    <span class="page-numbers current">1</span>
                                    <router-link to="/blog-left-sidebar" class="page-numbers">2</router-link>
                                    <router-link to="/blog-left-sidebar" class="page-numbers">3</router-link>
                                    <router-link to="/blog-left-sidebar" class="next page-numbers" title="Next Page"><i class="ri-arrow-right-line"></i></router-link>
                                </div> -->
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.activeclass:active{
color: brown;
}
</style>
<script>
import axios from "axios";
import BlogSkleton from './BlogSkleton.vue'
export default {
    name: 'Blog',
    components: {
            BlogSkleton
                },
    data() {
        return {
            isLoading: true,
            blogs: [],
            categories: [],
            popularBlogs: [],
            baseUrl: 'https://content.meander.software/',
            currentPage: 1,
            itemsPerPage: 12,
            totalItems: 0,
            totalPages: 0,
        };
    },
    created() {
    this.fetchBlogs();
  },

    watch: {
    // eslint-disable-next-line no-unused-vars
    '$route.query.slug'(newSlug, oldSlug) {
      this.fetchBlogs();
    },
  },

    methods: {
        async fetchBlogs() {
      const slug = this.$route.query.slug;
      if (slug) {
        await this.getBlogsByCaty(slug);
      } else {
        await this.getBlogs();
      }
    },
        async getBlogs() {
    
            try {
                const response = await axios.get(`${this.$baseUrl}/blogs?pagination[withCount]=true&pagination[page]=${this.currentPage}&populate=*`);
                // this.blogs = response.data;
                // Destructure the 'data' and 'total' properties from the response

                const { data ,meta     } = response;
               
                const reversedData = data;
                this.blogs = reversedData?.data?.reverse();
                this.currentPage = meta.pagination.page,
                    this.totalItems = meta.pagination.total,
                    this.totalPages = meta.pagination.pageCount
           
            } catch (error) {
                console.log(error);
            }
        },
        async getBlogsByCaty(){
            const slug = this.$route.query.slug;
 try {
    const response = await axios.get(`${this.$baseUrl}/blog-categories?filters[slug][$eq]=${slug}&populate=blogs.image`);
    const data = response;
    const reversedData = data.data.data[0]?.attributes?.blogs;

      this.blogs = reversedData?.data?.reverse();
                // this.currentPage = meta.pagination.page,
                //     this.totalItems = meta.pagination.total,
                //     this.totalPages = meta.pagination.pageCount
           
 } catch (error) { 
    console.warn(error);
 }
        },
        async getCategories() {
            const response = await axios.get(`${this.$baseUrl}/blog-categories`);
            this.categories = response.data.data;
        },

        // async fetchPopularPosts() {
        //   axios.get(`${this.$baseUrl}/blogs?pagination[limit]=10&populate=image`)
        //     .then(response => {
        //       this.popularBlogs = response.data.data;
        //     })
        //     .catch(error => {
        //     });
        // },

        async fetchPopularPosts() {
            const response = await axios.get(`${this.$baseUrl}/blogs?pagination[limit]=5&populate=image`);
            this.popularBlogs = response.data;
        },

        formatDate(dateString) {
            const date = new Date(dateString);
            // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', { dateStyle: 'long' }).format(date);
        },
        onPageChange(page) {
            this.currentPage = page;
            this.getBlogs();
        },

    },
    mounted: async function () {
        // const slug = this.$route.query.slug
      
        // slug ?   await this.getBlogsByCaty() : await this.getBlogs()
        await this.getCategories();
        await this.fetchPopularPosts();
        setTimeout(() => {
      // Set isLoading to false when your data is ready
      this.isLoading = false;
    }, 1000); 
    }

};
</script>