<template>
    <div>
       
       
        <!-- Start Features Area -->
        <div class="features-area ptb-100 bg-F7F7FF">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title text-uppercase  ">Why Choose Meander One
</span>
                    <h2>Empower the Power of Seamless API Integration
</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon">
                                <i class="ri-eye-line"></i>
                            </div>
                            <h3>Vast API Marketplace</h3>
                            <p>  Explore an extensive selection of APIs from our diverse marketplace, providing limitless integration possibilities.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg2">
                                <i class="ri-stack-line"></i>
                            </div>
                            <h3>Intuitive Interface</h3>
                            <p>Our user-friendly platform simplifies the API integration process, making it effortless to connect and utilize APIs.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg3">
                                <i class="ri-leaf-line"></i>
                            </div>
                            <h3>Developer-Focused Tools</h3>
                            <p> Accelerate your integration projects with our comprehensive suite of developer tools, including code snippets </p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg4">
                                <i class="ri-secure-payment-line"></i>
                            </div>
                            <h3>Real-time Monitoring  </h3>
                            <p>Gain valuable real-time insights into API performance, usage, and errors, enabling effective optimization of your integrations.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg5">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>Collaborative Workspaces</h3>
                            <p>Foster collaboration among your team members in dedicated workspaces, driving teamwork and expediting project completion.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg6">
                                <i class="ri-pie-chart-2-line"></i>
                            </div>
                            <h3>Trusted Security </h3>
                            <p>Rest assured that your data and integrations are safeguarded by robust security measures and compliance with industry standards.</p>
                        </div>
                    </div>
                    <!-- <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                        <div class="view-more-box">
                            <a href="#" class="default-btn">View More</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- End Features Area -->

    </div>
</template>

<script>
export default {
    name: 'WhyChooseUs',
}
</script>