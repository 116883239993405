<template>
    <div class="main-banner-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="main-banner-content">
                        <div class="content">
                            <span class="sub-title text-uppercase  ">#Business Solutions For You</span>
                            <h1>Streamline, Scale, Succeed: Software Solutions for Modern Business </h1>
                            <button @click="openCalendly" class="default-btn">Schedule Demo</button>
                            <button class="default-btn ms-2" @click="openPopup">Explore Solutions</button>
                        </div>
                        <TrustedBy />
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="main-banner-image" data-aos="fade-up">
                        <img src="../../assets/images/banner/banner-app-new.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <div class="shape-overlay"></div>
        <div class="banner-shape1"><img src="../../assets/images/shape/shape9.png" alt="image"></div>
        <div class="banner-shape2"><img src="../../assets/images/shape/shape7.png" alt="image"></div>
        <div class="banner-shape3"><img src="../../assets/images/shape/shape2.png" alt="image"></div>
        <div class="banner-shape4"><img src="../../assets/images/shape/shape10.png" alt="image"></div>
        <div class="banner-shape5"><img src="../../assets/images/shape/shape11.png" alt="image"></div>
    </div>
</template>

<script>
import TrustedBy from '../HomeOne/TrustedBy'

export default {
    name: 'MainBanner',
    props: {
  openPopup: Function, // Use the Function type directly

},
    components: {
        TrustedBy
    },
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>