<template>
    <!-- <Preloader /> -->
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <span class="sub-title text-uppercase  ">Meander Training</span>
                        <h2>Most Probably You Are Getting Best App Ever</h2>
                        <p>At Meander Training, we're dedicated to your success. Our hands-on courses, led by industry experts, equip you to conquer real-life challenges. Whether you prefer online or in-person learning, Meander is your pathway to expertise.
</p>
                        <div class="features-text">
                            <h6>Our Mission</h6>
                            <p>Bridging the gap between theory and practice, we empower you with the skills and knowledge to excel in your career, boosting your confidence and creativity.</p>
                        </div>
                        <div class="features-text">
                            <h6>Our Vision</h6>
                            <p>To lead globally in quality education, empowering our students to drive innovation and positive change in their industries, making learning limitless.</p>
                        </div>
                        <div class="btn-box">

                            <button @click="openCalendly" class="default-btn">Schedule Demo</button>
                            <!-- <router-link to="/features-one" class="link-btn">See All Features</router-link> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-img">
                        <img src="../../assets/images/app/app-img8.png" data-aos="fade-up" alt="about">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Preloader from '../Layout/Preloader.vue'
export default {
    name: 'About',
    components: {
        Preloader
    },       
    // data() {
    //     return {
    //         isLoading: true,
    //         baseUrl: 'https://content.meander.software/',
    //         aboutUs: Object
    //     }
    // },

 
}
</script>