<template>
    <div class="team-area pb-75"  >
       
        <div class="container">
            <div class="section-title" >
                <!-- <span class="sub-title text-uppercase  ">{{fieldname}}</span> -->
                <h2>{{fieldname}}</h2>
            </div>
            <div class="row justify-content-center  ">
                <div class="col-lg-3 col-md-6 col-sm-6  " v-for="app in this.relatedApps.slice(0,4)"
                                                    :key="app.id"  @click="refreshData(app)">
                    <div class="single-team-member">
                        <div class="image">
                            <img :src='`https://content.meander.software/${app?.attributes.circle_image.data.attributes.url}`' alt="image">
                            <div  >
                            <!-- <span>Client since 2020</span> -->
                        </div>
                        </div>

                        <router-link :to="`/app-clones/${app?.attributes?.slug}`" @click="refreshPage(app?.attributes?.slug)" class="nav-link">
                        <!-- <p>{{ app?.attributes?.title }}</p> -->
                            <div class="content">
                            <h3>{{app?.attributes?.name}}</h3>
                            <span>{{ fieldname }}</span>
                        </div></router-link>
                       
                        <ul class="social-links">
                            <li>
                                <!-- <a href="https://facebook.com/" target="_blank">
                                    <i class="ri-facebook-fill"></i>
                                </a> -->
                            </li>
                            <li>
                                <!-- <a href="https://linkedin.com/" target="_blank">
                                    <i class="ri-linkedin-fill"></i>
                                </a> -->
                            </li>
                            <li>
                                <!-- <a href="https://twitter.com/" target="_blank">
                                    <i class="ri-twitter-fill"></i>
                                </a> -->
                            </li>
                            <li>
                                <!-- <a href="https://instagram.com/" target="_blank">
                                    <i class="ri-instagram-line"></i>
                                </a> -->
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: 'Team',
    data() {
        return {
            baseUrl: 'https://content.meander.software/',
            relatedApps:[],
            appDATA:[],
            filteredApps:[],
            matchedPerson:[],
            slugToMatch:'',
            fieldname:''
        }
    },
    props:{
        refreshPage:Function
    },
    methods: {

        async getApp() {
  try {
    const response = await axios.get(`${this.baseUrl}/api/apps?populate=circle_image`);
    const { data } = response;
    this.appDATA = data;
   
    
  } catch (error) {
    console.error(error);
  }
},





        
        returnData(data) {
            const idToDelete = this.$route?.params?.slug;
            const newData = data.filter(item => {
                return item?.attributes?.slug !== idToDelete;
            });
            return newData;
        },
        refreshData(data) {
            const newSlug = data?.attributes?.slug;
            this.$emit('change-data', newSlug);
        }
    },
    async mounted() {
        let apps = await JSON.parse(localStorage.getItem('relatedApps'))
        this.fieldname = apps.attributes.name
        const slugDelete = this.$route?.params?.slug;
        
        this.relatedApps =  apps.attributes.related_apps.data.filter((app) => app.attributes.slug !== slugDelete);
         
        await this.getApp()

    }
}
</script>
<style>
   
</style>