<template>
    <div>
       
       
        <div class="features-area ptb-100 bg-F7F7FF">
            <div class="container">
                <div class="section-title">
                    <!-- <span class="sub-title text-uppercase  ">KEY FEATURES</span> -->
                    <h2>   Unlock Exclusive Partnership Benefits with Meander software</h2>
                </div>
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon">
                                <i class="ri-eye-line"></i>
                            </div>
                            <h3>Access to Exclusive Products or Services</h3>
                            <p> Gain access to our innovative and high-quality products/services that are not available to the general public. Stand out in your market with unique offerings that set you apart from the competition.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg2">
                                <i class="ri-stack-line"></i>
                            </div>
                            <h3>Marketing Support</h3>
                            <p> Leverage our marketing expertise and resources to amplify your brand presence. Benefit from co-branded marketing materials, promotional campaigns, and strategic guidance to effectively reach and engage your target audience.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg3">
                                <i class="ri-leaf-line"></i>
                            </div>
                            <h3>Training Opportunities</h3>
                            <p> Access comprehensive training and resources to enhance product understanding, sales, marketing skills, and stay ahead in industry trends. Equip yourself and your team for success with our tailored programs.
</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg4">
                                <i class="ri-secure-payment-line"></i>
                            </div>
                            <h3>Potential Revenue Streams</h3>
                            <p>Maximize your earning potential by unlocking new revenue opportunities. Benefit from revenue-sharing arrangements, referral commissions, or exclusive discounts on our offerings. Boost your bottom line with tailored incentives designed for your success</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg5">
                                <i class="ri-cloud-line"></i>
                            </div>
                            <h3>
Enhanced Brand Reputation</h3>
                            <p> Align your business with a trusted and reputable brand.
 Partnering with Meander software enhances your credibility in the eyes of customers, 
bolstering your reputation and fostering trust and loyalty among your target audience.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                        <div class="single-features-item">
                            <div class="icon bg6">
                                <i class="ri-pie-chart-2-line"></i>
                            </div>
                            <h3>
                                Collaboration and Networking Opportunities:</h3>
                            <p> Connect with like-minded professionals, industry leaders, and potential customers. Our partnership opens doors to valuable networking opportunities, facilitating collaboration, knowledge-sharing, and business growth.</p>
                        </div>
                    </div>
                    <!-- <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                        <div class="view-more-box">
                            <a href="#" class="default-btn">View More</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- End Features Area -->

    </div>
</template>

<script>
export default {
    name: 'Featuresist',
}
</script>