<template>
    <div class="contact-area ptb-100">
      <div v-if="successMessage" :style="{ backgroundColor: successMessage ? 'green' : (failMessage ? 'red' : 'null') }" class="success-message">
  {{ successMessage || failMessage }}
</div>



        <div class="container">
            <div class="section-title">
                <h2>Get in Touch</h2>
                <p>The IT industry offers a sea of options, from platforms, programming languages methodologies, technologies, tools, and more.</p>
            </div>
            <div class="contact-form">
                <form id="contactForm" @submit.prevent="submitForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <input  v-model="formData.name" required type="text" name="formData.name" class="form-control" id="formData.name" placeholder="Eg: Sarah Taylor">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <input  v-model="formData.email" required type="email" name="formData.email" class="form-control" id="formData.email" placeholder="Enter your email">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <input  v-model="formData.phone" required type="text" name="formData.phone" class="form-control" id="formData.phone" placeholder="Enter your phone number">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <input  v-model="formData.admin_notes" required type="text" name="formData.admin_notes" class="form-control" id="formData.admin_notes" placeholder="Enter your subject">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="form-group">
                                <textarea  v-model="formData.notes" required name="formData.notes" id="formData.notes" class="form-control" cols="30" rows="6" placeholder="Enter message..."></textarea>
                            </div>
                        </div> 



 <div >
  <recaptcha></recaptcha>
 </div>

                  
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <button type="submit" class="default-btn"><i class='bx bx-paper-plane'></i> Send Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="maps">
                    <!-- <iframe src="https://www.googl529129888566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d9f3b8019f2991%3A0x58d5929e21a62e5!2sDinosaur%20Bar-B-Que!5e0!3m2!1sen!2sbd!4v1593527523138!5m2!1sen!2sbd"></iframe> -->
            <iframe src="https://www.google.com/maps?q=Tdi+Business+Centre%2C+18+-+11th%2C+VR+Punjab+Mall+Rd%2C+Sector+118%2C+Sahibzada+Ajit+Singh+Nagar%2C+Punjab+160055&output=embed"></iframe>
        </div>
    </div>
</template>
<style>
.success-message {
    position: fixed;
    right: 0px;
    top:200px;
    width: 400px;
 /* Green background color */
  color: white; /* White text color */
  padding: 10px; /* Add some padding */
  margin-top: 10px; /* Add space below the form */
  border-radius: 5px; /* Add rounded corners */
}
</style>
<script>
import axios from 'axios';
 import recaptcha from './recaptcha2.vue'
import { ref, onMounted } from 'vue'
 
export default {
  name: 'Contact',
  components:{
    recaptcha
  },
  data() {
    return {
        recaptchaDiv :ref(null),
     recaptcha :null,
      formData: {
        title:'',
        name: '',
    
        email:  '',
        phone: '',
        admin_notes: '',
        notes: '',

        showRecaptcha: true,
			loadingTimeout: 30000 ,
        technology: [
          "6411a6e851836b26af966e3a"
        ],
        lead_status:"63e396de2052e0098e18deec",
        lead_stage:"63e391a22052e0098e18de7f",
        source: "63ef454c5fb91668b7f123d1",
        channelId: "652ce6c89d423da8d2ab52c3",
        type:'63e39dd62052e0098e18e015',
        outsource:"63ef454c5fb91668b7f123d1",
    },
    newsletterEmail:'',
    successMessage: null,
   failMessage: null,

    };

  },
  methods: {

   


    submitForm() {
      // Create a token variable with your actual authentication token
      // const token = '';
      
    this.formData.title = this.formData.name;
        // Make an API request if the fields are not empty
        axios
          .post('https://do.meander.software:5000/api/outsourcingLeads/add', this.formData)
          .then((response) => {
            // Handle the API response here if needed
            console.log('API Response:', response.data);
            this.successMessage = 'Message sent successfully';
            setTimeout(() => {
            this.successMessage = null;
          }, 2000);
            // Reset form fields after successful submission
            this.formData.name = '';
            this.formData.email = '';
            this.formData.phone = '';
            this.formData.admin_notes = '';
            this.formData.notes = '';
          })
          .catch((error) => {
            // Handle any errors that occur during the request
            console.error('API Error:', error);
            this.failMessage = 'Message failed';

          });
      
    }
  },
  created(){
    this.formData.email= localStorage.getItem("email")


  }
}
</script>