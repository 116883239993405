<template>
    <div class="how-it-works-area ptb-100">
        <div class="container">
            <div class="how-it-works-content">
                <div class="number">1</div>
                <div class="row m-0">
                    <div class="col-lg-3 col-md-12 p-0">
                        <div class="box">
                            <h3>Step 1</h3>
                            <span>Contact us</span>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12 p-0">
                        <div class="content">
                            <p>Get in touch with our team and share your requirements for a . Our experts will provide you with a detailed consultation and assist you in choosing the best software solution for your business.</p>
                            <img src="../../assets/images/how-it-works/create-account.jpg" alt="create-account">
                        </div>
                    </div>
                </div>
            </div>
            <div class="how-it-works-content">
                <div class="number">2</div>
                <div class="row m-0">
                    <div class="col-lg-3 col-md-12 p-0">
                        <div class="box">
                            <h3>Step 2</h3>
                            <span>Customize the app</span>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12 p-0">
                        <div class="content">
                            <p>Once you have selected the app, our team will help you customize it to your specific business needs. From branding and design to features and functionalities, we will tailor the app to meet your requirements and preferences.</p>
                            <img src="../../assets/images/how-it-works/select-coin.jpg" alt="select-coin">
                        </div>
                    </div>
                </div>
            </div>
            <div class="how-it-works-content">
                <div class="number">3</div>
                <div class="row m-0">
                    <div class="col-lg-3 col-md-12 p-0">
                        <div class="box">
                            <h3>Step 3</h3>
                            <span>Test the app</span>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12 p-0">
                        <div class="content">
                            <p>Once the customization is complete, we will provide you with a demo version of the app for testing. You can try out the app and provide feedback on any further adjustments that you may require.</p>
                            <!-- <ul>
                                <li>Credit Card: <strong>Visa</strong>, <strong>MasterCard</strong>, <strong>Discover</strong>, <strong>American Express</strong>, <strong>JCB</strong>, <strong>Visa Electron</strong>. The total will be charged to your card when the order is shipped.</li>
                                <li><strong>meander_software</strong> features a Fast Checkout option, allowing you to securely save your credit card details so that you don't have to re-enter them for future purchases.</li>
                                <li>PayPal: Shop easily online without having to enter your credit card details on the website.Your account will be charged once the order is completed. To register for a PayPal account, visit the website <a href="#" target="_blank">paypal.com.</a></li>
                            </ul> -->
                            <img src="../../assets/images/how-it-works/select-payment.jpg" alt="select-payment">
                        </div>
                    </div>
                </div>
            </div>
            <div class="how-it-works-content">
                <div class="number">4</div>
                <div class="row m-0">
                    <div class="col-lg-3 col-md-12 p-0">
                        <div class="box">
                            <h3>Step 4</h3>
                            <span>Launch the app</span>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12 p-0">
                        <div class="content">
                            <p>After the testing and refinement phase is complete, we will launch the app on your behalf. We will provide you with all the necessary technical support and assistance to ensure a smooth launch.</p>
                            <img src="../../assets/images/how-it-works/payment-method.jpg" alt="payment-method">
                        </div>
                    </div>
                </div>
            </div>
            <div class="how-it-works-content">
                <div class="number">5</div>
                <div class="row m-0">
                    <div class="col-lg-3 col-md-12 p-0">
                        <div class="box">
                            <h3>Step 5</h3>
                            <span>Maintain the app</span>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12 p-0">
                        <div class="content">
                            <p>We provide comprehensive support and maintenance services to ensure that your  is always up-to-date and operating efficiently. Our team of experts will be available to assist you with any queries or issues that may arise, allowing you to focus on growing your business.</p>
                            <img src="../../assets/images/how-it-works/confirm-order.jpg" alt="confirm-order">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HowItWorks',
   
}
</script>