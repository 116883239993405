<template>
    <div class="team-area pt-100 pb-75" v-if="data">
        <div class="container">
            <div class="section-title" >
                <!-- <span class="sub-title text-uppercase  ">{{fieldname}}</span> -->
                <h2>Technologies We Are Expert In</h2>
            </div>
            <div class="row justify-content-center">


                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4"
                    v-for="technlology in technologies[0]?.attributes?.technologies?.data" :key="technlology?.id">
                    <div class="single-team-box">
                        <!-- <div class="image"> -->
                        <div><img :src="baseUrl + technlology?.attributes?.logo?.data?.attributes?.url"></div>
                        <!-- </div> -->
                        <div class="content">
                            <!-- <h3>{{ technlology?.attributes?.name }}</h3> -->
                            <!-- <span>Client since 2018</span> -->
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: 'Team',
    props: {
        data: {
            type: [], // Set the prop type to be Any
            required: true,
        },
    }, data() {
        return {
            baseUrl: 'https://content.meander.software/',
            technologies: []
        }
    },
    methods: {
        async getTechnologies() {
            const slug = this.$route.params.slug;
            if(slug){
 try {
                // const slug = this.$route.params.slug;
                // Fetch best features data from Strapi API
                    const response = await axios.get(`${this.$baseUrl}/apps?filters[slug][$eq]=${slug}&populate=*&populate=technologies.logo`);
                    const { data       } = response
                    this.technologies = data.data;
                
            
            } catch (error) {
            }
        }}

    },
    mounted: async function () {
        await this.getTechnologies();
        this.isLoading = false;
    }
}
</script>