<template>
    <div class="team-area pt-100 pb-75" >
        <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-9">
                                <img src="../../assets/images/techapi/SendbirdChatAPI.webp" alt="image">
                            </div>
                        </div>
                        <div class="content">
                            <h3>Sendbird Chat</h3>
                            <!-- <span>Development</span> -->
                        </div>
                    </div>
                </div>
                
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div class="single-team-box">
                            <div class="image">
                                
                                <div class="bg-9">

                                    <img src="../../assets/images/techapi/Google-Map-API.webp" alt="image">
                                </div>
                        </div>
                            <div class="content">
                                <h3>Google Map </h3>
                                <!-- <span>Art Director</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div class="single-team-box">
                            <div class="image">
                                <div class="bg-9">

                                    <img src="../../assets/images/techapi/Agora-API-for-Message-_-chat.webp" alt="image">
                                </div>
                        </div>
                            <div class="content">
                                <h3>Agora </h3>
                                <!-- <span>Designer</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div class="single-team-box">
                            <div class="image">
                                <div class="bg-9">

                                    <img src="../../assets/images/techapi/Google-API.webp" alt="image">
                                </div>
                        </div>
                            <div class="content">
                                <h3>Google API</h3>
                                <!-- <span>Marketing Lead</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div class="single-team-box">
                            <div class="image">
                                <div class="bg-9">

                                    <img src="../../assets/images/techapi/Twilio-Message-API.webp" alt="image">
                                </div>
                        </div>
                            <div class="content">
                                <h3>Twilio Message </h3>
                                <!-- <span>Development</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div class="single-team-box">
                            <div class="image">
                                <div class="bg-9">

                                    <img src="../../assets/images/techapi/ChatGPT-AI-API.webp" alt="image">
                                </div>
                        </div>
                        <div class="content">
                            <h3>ChatGPT AI </h3>
                            <!-- <span>Front-End Development</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-9">

                                <img src="../../assets/images/techapi/Meta-Business-API.webp" alt="image">
                            </div>
                        </div>
                        <div class="content">
                            <h3>Meta Business </h3>
                            <!-- <span>Head of Team</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-9">

                                <img src="../../assets/images/techapi/Razorpay-Payment-API.webp" alt="image">
                            </div>
                        </div>
                        <div class="content">
                            <h3>Razorpay Payment </h3>
                            <!-- <span>Front-End Developer</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-9">

                                <img src="../../assets/images/techapi/Paypal-Payment-API.webp" alt="image">
                            </div>
                        </div>
                        <div class="content">
                            <h3>Paypal Payment </h3>
                            <!-- <span>Art Director</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6">
                    <div class="single-team-box">
                        <div class="image">
                            <div class="bg-9">
                                
                                <img src="../../assets/images/techapi/StripePaymentAPI.webp" alt="image">
                            </div>
                        </div>
                        <div class="content">
                            <h3>Stripe Payment </h3>
                            <!-- <span>Designer</span> -->
                        </div>
                    </div>
                </div>
           
             
            </div>

         
    </div>
</div></template>

<script>
export default {
    name: 'Technology',
   
}
</script>