<template>
    <div class="screenshots-area bg-black ptb-100" v-if="data[0]?.attributes?.screens?.data[0]?.attributes?.web_screens?.data">
        <div class="container">
            <div class="section-title">
                <span class="sub-title text-uppercase  ">Dashboard</span>
                <h2>Explore Our Powerful Admin Panel</h2>
            </div>
            <div class="screen-swiper-slides">
                <carousel
                    :wrap-around="true"
                    :autoplay="3000"
                    :settings='settings'
                    :breakpoints='breakpoints'
                >
                    <slide 
                        v-for="slide in data[0]?.attributes?.screens?.data[0]?.attributes?.web_screens?.data" 
                        :key="slide.id"
                    >
                        <div class="swiper-slide">
                            <img :src="baseUrl+slide?.attributes?.url" alt="screenshots">
                        </div>
                    </slide>
                    
                    <template #addons>
                        <navigation>
                            <template #next>
                                <i class='ri-arrow-right-s-line'></i>
                            </template>
                            <template #prev>
                                <i class='ri-arrow-left-s-line'></i>
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>
            <div class="free-trial-btn">
                <button @click="openCalendly"  class="default-btn">Schedule Demo</button>
            </div>
        </div>
        </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Screenshots',
    props: {
        data: {
            type: [], // Set the prop type to be Any
            required: true,
        },
    },
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  },
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        baseUrl: 'https://content.meander.software/',
    }),
})
</script>