<template>
    <div class="features-area pt-100 pb-75">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-content">
                        <span class="sub-title text-uppercase  ">{{ data[0]?.attributes?.ms_industries_different_other_companies?.data[0]?.attributes?.name||"#Why Us" }}</span>
                        <h2>{{ data[0]?.attributes?.ms_industries_different_other_companies?.data[0]?.attributes?.title||"Why Choose Meander Software for Your  Needs?" }}</h2>
                        <p>{{ data[0]?.attributes?.ms_industries_different_other_companies?.data[0]?.attributes?.description||"At Meander Software, we have extensive experience in providing custom IT solutions for the retail & ecommerce industry. Our team of experts understands the unique challenges faced by this industry, and we are committed to providing innovative and effective solutions to help our clients succeed. At Meander Software, we are committed to delivering exceptional IT solutions to our clients in the retail & ecommerce industry. Contact us today to learn more about how we can help your business succeed." }}</p>
                        <div class="btn-box">
                            <a @click="openCalendly" class="default-btn">Schedule Demo</a>
                            <!-- <a href="#" class="link-btn">See All Features</a> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12" v-if="data">
                    <div class="list-of-features">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6 col-md-6" v-for="feature in data[0]?.attributes?.ms_industries_keys_different_other_companies?.data.slice(0, 4)" :key="feature?.id">
                                <div class="features-item with-border">
                                    <div class="icon">
                                        <i class="ri-eye-line"></i>
                                    </div>
                                    <h3>{{feature?.attributes?.name}}</h3>
                                      <p>{{feature?.attributes?.title}}</p>
                                </div>
                            </div>
                          
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IndustriesDiffcompany',
    props:{
     data:[],
    },
    methods: {
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }
  }
}
</script>