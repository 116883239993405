<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Technologies" />
        <ChallengesAndTrack />
        <Partner  /> 
        <CustomFeatures />
        <BestFeaturesEver />
        <SoftwareIntegrations />
        <AppScreens />
        <ChallengesAndTrackDark />
        <SoftwareIntegration />
        <Faq />
        <PartnerTwo />
        <FeedbackTwo />

        <!-- <About />
        <Funfacts />
        <Stats />
        <SoftwareIntegrations />
        <OurTeam />
        <CustomerReviews class="pt-0" />
        <Partner class="bg-f9f9f9" /> -->
        <FooterStyleFour />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
 import ChallengesAndTrack from '../AboutTwo/ChallengesAndTrack'
 import Partner from '../Common/Partner'
import CustomFeatures from "../FeaturesTwo/CustomFeatures"
import BestFeaturesEver from "../FeaturesOne/BestFeaturesEver"
import SoftwareIntegrations from "../AboutOne/SoftwareIntegrations"
import AppScreens from "../HomeOne/AppScreens";
import ChallengesAndTrackDark from  "../AboutOne/ChallengesAndTrack"
import SoftwareIntegration from "../Common/SoftwareIntegrations"
  import Faq from "../Faq/Faq"
  import PartnerTwo from "../Common/PartnerTwo"
  import FeedbackTwo  from "../Feedback/FeedbackTwo"
 import FooterStyleFour from '../Layout/FooterStyleFour'





// import About from '../AboutOne/About'
// import Funfacts from '../AboutOne/Funfacts'
// import Stats from '../AboutOne/Stats'
// import OurTeam from '../AboutOne/OurTeam'
// import CustomerReviews from '../Common/CustomerReviews'
import Footer from '../Layout/Footer'

export default {
    name: 'Technologies',
    components: {
        Navbar,
        PageTitle,
        ChallengesAndTrack,   
             Partner,
CustomFeatures,
BestFeaturesEver,
SoftwareIntegrations,
AppScreens,
ChallengesAndTrackDark,
SoftwareIntegration,
Faq,
PartnerTwo,
FeedbackTwo,
        // About,
        // Funfacts,
        
        // Stats,
        // SoftwareIntegrations,
        // OurTeam,
        // CustomerReviews,
        // Partner,
        FooterStyleFour,
    }
}
</script>