<template>
  <FormModal v-if="showPopup" :closePopup="closePopup" />

    <div >
      <Navbar :openPopup="openPopup" />

        <Gallery :data="successData"  />
        <Funfacts />
        <!-- <PageTitle pageTitle="About Us" /> -->
        <!-- <AboutMeander />
        <Funfacts />
        <ChallengesAndTrack />
        
        <SoftwareIntegrations />
        <OurTeam />
        <CustomerReviews class="pt-0" />
        <Partner class="bg-f9f9f9" /> -->
        <!-- <FooterStyleFour /> -->
    </div>
</template>

<script>
import Navbar from '../../Layout/Navbar'
import Gallery from "./Gallery";
import Funfacts from "../../AboutOne/Funfacts.vue"
// import PageTitle from '../Common/PageTitle'
// import AboutMeander from '../AboutOne/AboutMeander'
// import Funfacts from '../AboutOne/Funfacts'
// import ChallengesAndTrack from '../AboutOne/ChallengesAndTrack'
// import Stats from '../AboutOne/Stats'
// import SoftwareIntegrations from '../AboutOne/SoftwareIntegrations'
// import OurTeam from '../AboutOne/OurTeam'
// import CustomerReviews from '../Common/CustomerReviews'
// import Partner from '../Common/Partner'
import axios from 'axios';
import FormModal from '../../HomeThree/FormModal.vue';

export default {
    name: 'SuccessStories',
    components: {
    Navbar,
    Gallery,
    Funfacts,
    // Stats,
    // PageTitle,
    // AboutMeander,
    // Funfacts,
    // ChallengesAndTrack,
    // SoftwareIntegrations,
    // OurTeam,
    // CustomerReviews,
    // Partner,
 
    FormModal
},
    data() {
    return {
      baseUrl: "https://content.meander.software/",
      successData: {},
      
      isLoading: false,
      showPopup:false
    };
  },
  async mounted() {
    await this.getData();


  },
    methods:{
      openPopup(){
     this.showPopup  = true
    },
    closePopup(){
     this.showPopup= false
    },
        async getData() {
     
      try {
        const response = await axios.get(`${this.$baseUrl}/ms-succes-stories?&populate=*`
        );
        const { data      } = response;
        document.title ="Success Stories"
        this.successData = data.data.reverse();
      } catch (error) { console.log(error);}
    },
 
    }
}
</script>
