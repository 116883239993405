<template>
    <div class="free-trial-area ptb-100">
        <div class="container">
            <div class="free-trial-content bg-color">
                <span class="sub-title text-uppercase  ">FREE TRIAL</span>
                <h2>Start Your 14 Days Free Trials Today!</h2>
                <form class="free-trial-form">
                    <input type="text" class="input-newsletter" placeholder="Enter Your Email Address" name="email">
                    <button type="submit" class="default-btn">Get Started</button>
                </form>
                <div class="shape8"><img src="../../assets/images/shape/shape19.png" alt="shape"></div>
                <div class="shape9"><img src="../../assets/images/shape/shape20.png" alt="shape"></div>
                <div class="lines">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FreeTrial'
}
</script>