<template>
    <div class="team-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title text-uppercase  ">OUR HAPPY CLIENTS</span>
                <!-- <h2>Meet With Our Team Member</h2> -->
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-team-member">
                        <div class="image">
                            <img src="../../assets/images/team/team1.jpg" alt="image">
                        </div>
                        <div class="content">
                            <h3>James Andy</h3>
                            <span>Client Since 2019</span>
                        </div>
                        <!-- <ul class="social-links">
                            <li>
                                <a href="https://facebook.com/" target="_blank">
                                    <i class="ri-facebook-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://linkedin.com/" target="_blank">
                                    <i class="ri-linkedin-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/" target="_blank">
                                    <i class="ri-twitter-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://instagram.com/" target="_blank">
                                    <i class="ri-instagram-line"></i>
                                </a>
                            </li>
                        </ul> -->
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-team-member">
                        <div class="image">
                            <img src="../../assets/images/team/team2.jpg" alt="image">
                        </div>
                        <div class="content">
                            <h3>Steven Smith</h3>
                            <span>Client since 2015</span>
                        </div>
                        <!-- <ul class="social-links">
                            <li>
                                <a href="https://facebook.com/" target="_blank">
                                    <i class="ri-facebook-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://linkedin.com/" target="_blank">
                                    <i class="ri-linkedin-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/" target="_blank">
                                    <i class="ri-twitter-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://instagram.com/" target="_blank">
                                    <i class="ri-instagram-line"></i>
                                </a>
                            </li>
                        </ul> -->
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-team-member">
                        <div class="image">
                            <img src="../../assets/images/team/team3.jpg" alt="image">
                        </div>
                        <div class="content">
                            <h3>Sarah Taylor</h3>
                            <span>Client since 2021</span>
                        </div>
                        <!-- <ul class="social-links">
                            <li>
                                <a href="https://facebook.com/" target="_blank">
                                    <i class="ri-facebook-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://linkedin.com/" target="_blank">
                                    <i class="ri-linkedin-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/" target="_blank">
                                    <i class="ri-twitter-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://instagram.com/" target="_blank">
                                    <i class="ri-instagram-line"></i>
                                </a>
                            </li>
                        </ul> -->
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-team-member">
                        <div class="image">
                            <img src="../../assets/images/team/team4.jpg" alt="image">
                        </div>
                        <div class="content">
                            <h3>Alina Aly</h3>
                            <span>Client Since 2015</span>
                        </div>
                        <!-- <ul class="social-links">
                            <li>
                                <a href="https://facebook.com/" target="_blank">
                                    <i class="ri-facebook-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://linkedin.com/" target="_blank">
                                    <i class="ri-linkedin-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/" target="_blank">
                                    <i class="ri-twitter-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://instagram.com/" target="_blank">
                                    <i class="ri-instagram-line"></i>
                                </a>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurTeam'
}
</script>