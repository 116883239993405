import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import "./assets/custom.scss";
import axios from "axios";
import VueAxios from "vue-axios";

const DEV_API_URL = "https://content.meander.software/api"; // Development URL
const PROD_API_URL = "https://content.meander.software/api"; // Production URL

const app = createApp(App);
// app.use(router);
app.config.globalProperties.$baseUrl =
process.env.NODE_ENV == "production" ? PROD_API_URL : DEV_API_URL;
app.use(VueAxios, axios);
app.use(AOS.init()).use(router).mount("#app");
